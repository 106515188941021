import { ClientOptions, Execution, FluentExecution, OperationInput } from '@garner-health/api-client-core';
import type { Headers } from '@garner-health/api-client-core';
export type GetCareNavigatorsRequest = {
  headers?: Headers;
  query: {
    offset?: number;
    limit?: number;
    client_id: number;
    email?: string;
  };
};
export type GetCareNavigatorsResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      total: number;
      offset: number;
      limit: number;
    } & {
      items: {
        id: number;
        created_at: string;
        updated_at: string;
        first_name: string;
        last_name: string;
        preferred_name?: string;
        email: string;
        job_title?: string;
        client_id: number;
        data_security_agreement?: {
          created_at: string;
          agreed: true;
        };
      }[];
      [k: string]: unknown;
    };
  };
};
export type CreateCareNavigatorRequest = {
  headers?: Headers;
  json: {
    first_name: string;
    last_name: string;
    preferred_name?: string;
    email: string;
    job_title?: string;
    client_id: number;
  };
};
export type CreateCareNavigatorResponses = {
  201: {
    status: 201;
    url: string;
    headers: Headers;
    json: {
      id: number;
      [k: string]: unknown;
    };
  };
  409: {
    status: 409;
    url: string;
    headers: Headers;
    json: {
      requestId?: string;
      message: string;
      error?: string;
      data?: {
        [k: string]: unknown;
      };
    };
  };
};
export type GetCareNavigatorRequest = {
  params: {
    id: number;
  };
  headers?: Headers;
};
export type GetCareNavigatorResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      id: number;
      created_at: string;
      updated_at: string;
      first_name: string;
      last_name: string;
      preferred_name?: string;
      email: string;
      job_title?: string;
      client_id: number;
      data_security_agreement?: {
        created_at: string;
        agreed: true;
      };
    };
  };
  404: {
    status: 404;
    url: string;
    headers: Headers;
    json: {
      requestId?: string;
      message: string;
      error?: string;
      data?: {
        [k: string]: unknown;
      };
    };
  };
};
export type PatchCareNavigatorRequest = {
  params: {
    id: number;
  };
  headers?: Headers;
  json: {
    first_name?: string;
    last_name?: string;
    job_title?: string;
    preferred_name?: string;
    data_security_agreement?: {
      agreed: true;
    };
  };
};
export type PatchCareNavigatorResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      id: number;
      created_at: string;
      updated_at: string;
      first_name: string;
      last_name: string;
      preferred_name?: string;
      email: string;
      job_title?: string;
      client_id: number;
      data_security_agreement?: {
        created_at: string;
        agreed: true;
      };
    };
  };
  404: {
    status: 404;
    url: string;
    headers: Headers;
    json: {
      requestId?: string;
      message: string;
      error?: string;
      data?: {
        [k: string]: unknown;
      };
    };
  };
};
export type DeleteCareNavigatorRequest = {
  params: {
    id: number;
  };
  headers?: Headers;
};
export type DeleteCareNavigatorResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      id: number;
      [k: string]: unknown;
    };
  };
  404: {
    status: 404;
    url: string;
    headers: Headers;
    json: {
      requestId?: string;
      message: string;
      error?: string;
      data?: {
        [k: string]: unknown;
      };
    };
  };
};
export type GetUrl = '/care-navigators' | '/care-navigators/{id}';
export type PostUrl = '/care-navigators';
export type PatchUrl = '/care-navigators/{id}';
export type DeleteUrl = '/care-navigators/{id}';
type RequestsByMethodAndPath = {
  'GET /care-navigators': GetCareNavigatorsRequest;
  'POST /care-navigators': CreateCareNavigatorRequest;
  'GET /care-navigators/{id}': GetCareNavigatorRequest;
  'PATCH /care-navigators/{id}': PatchCareNavigatorRequest;
  'DELETE /care-navigators/{id}': DeleteCareNavigatorRequest;
};
type ResponsesByMethodAndPath = {
  'GET /care-navigators': GetCareNavigatorsResponses;
  'POST /care-navigators': CreateCareNavigatorResponses;
  'GET /care-navigators/{id}': GetCareNavigatorResponses;
  'PATCH /care-navigators/{id}': PatchCareNavigatorResponses;
  'DELETE /care-navigators/{id}': DeleteCareNavigatorResponses;
};
export class CareNavigatorServiceV1 {
  constructor(private readonly options: ClientOptions) {}
  get<Url extends GetUrl>(
    url: Url,
    input: RequestsByMethodAndPath[`GET ${Url}`],
  ): FluentExecution<ResponsesByMethodAndPath[`GET ${Url}`]>;
  get(url: string, input: OperationInput = {}): FluentExecution<Record<number, any>> {
    return new Execution(this.options, 'GET', url, input, 1);
  }
  post<Url extends PostUrl>(
    url: Url,
    input: RequestsByMethodAndPath[`POST ${Url}`],
  ): FluentExecution<ResponsesByMethodAndPath[`POST ${Url}`]>;
  post(url: string, input: OperationInput = {}): FluentExecution<Record<number, any>> {
    return new Execution(this.options, 'POST', url, input, 1);
  }
  patch<Url extends PatchUrl>(
    url: Url,
    input: RequestsByMethodAndPath[`PATCH ${Url}`],
  ): FluentExecution<ResponsesByMethodAndPath[`PATCH ${Url}`]>;
  patch(url: string, input: OperationInput = {}): FluentExecution<Record<number, any>> {
    return new Execution(this.options, 'PATCH', url, input, 1);
  }
  delete<Url extends DeleteUrl>(
    url: Url,
    input: RequestsByMethodAndPath[`DELETE ${Url}`],
  ): FluentExecution<ResponsesByMethodAndPath[`DELETE ${Url}`]>;
  delete(url: string, input: OperationInput = {}): FluentExecution<Record<number, any>> {
    return new Execution(this.options, 'DELETE', url, input, 1);
  }
}
