import React from 'react';
import { AuthForgotPassword } from '@garner-health/components-auth';
import { FixedWidthLayout } from '@garner-health/components-common';
import { useStringResource } from '@garner-health/lib-ui-content-management';
import { analytics, Event } from '~/analytics';
import logger from '~/logging';
import { routes } from '~/router/routes';

const log = logger(__filename);

export const ForgotPasswordPage = () => (
  <FixedWidthLayout maxWidth="sm">
    <AuthForgotPassword
      headingText={useStringResource('auth', 'forgotPasswordHeadingText')}
      sendResetText={useStringResource('auth', 'sendResetText')}
      loginPageText={useStringResource('auth', 'loginPageText')}
      emailLabel={useStringResource('auth', 'emailLabel')}
      sentResetLinkText={useStringResource('auth', 'sentResetLinkText')}
      loginPageLink={routes.login()}
      errorText={{
        UNKNOWN: useStringResource('auth', 'unknownErrorText'),
      }}
      onSubmit={email => {
        analytics.track(Event.FORGOT_PASSWORD, { email });
        log.info({ strEmail: email }, 'Forgot password triggered');
      }}
    />
  </FixedWidthLayout>
);
