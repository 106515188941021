export const title = 'DataPro';
export const body =
  'This is the text title that is incredibly long and far too long for the short data type which is limited to two hundred and fifty six characters. Thankfully with a new Contentful space we are able to have much longer string resources that can be up to fifty thousand characters. With that long of a limit we can put whatever we want in it.';
export const accountButtonText = 'Account';
export const copyright = '© 2023 Garner Health Technologies, Inc.';
export const supportCta = 'For support, email {recommendation-support@getgarner.com}';
export const footerHelpText = 'Help';
export const termsOfService = 'Terms of service';
export const privacyStatement = 'Privacy statement';
export const errorPageHeader = 'Something went wrong';
export const errorPageSubheader = 'Please try again';
export const notFoundPageHeader = 'Not found';
export const notFoundPageSubheader =
  'We’re sorry but we can’t find that page. The link you followed may be broken or the page has been removed.';
export const termsOfServiceUrl = 'https://www.getgarner.com/terms-of-service';
export const privacyStatementUrl = 'https://www.getgarner.com/privacy';
export const supportEmailLink = 'mailto:recommendation-support@getgarner.com';
export const warningBannerText =
  'Garner DataPro is experiencing technical difficulties. You may experience degraded functionality temporarily.';
export const demoBannerText =
  'Your account is set to "Demo". All provider information is for demonstration purposes only.';
