import { AnalyticsBrowser } from '@segment/analytics-next';
import config from 'app-constants';
import { Logger } from '@garner-health/logging';
import { getTokenClaims } from '~/auth';
import logger from '~/logging';
import { Errors, Event } from './events';

export type UserTrackingProperties = Record<string, string | number | boolean | string[] | null | undefined>;

class Analytics {
  private analytics: AnalyticsBrowser;
  private globalProperties: UserTrackingProperties;
  private isDisabled: boolean;
  private log: Logger;

  constructor(writeKey: string) {
    this.analytics = AnalyticsBrowser.load({ writeKey });
    this.globalProperties = {};
    this.isDisabled = false;
    this.log = logger(__filename);
  }

  disable() {
    this.isDisabled = true;
  }

  getAllProps(props?: UserTrackingProperties) {
    if (!props) return this.globalProperties;
    return {
      ...this.globalProperties,
      ...props,
    };
  }

  initialize() {
    this.globalProperties = {}; // Reset object before adding initial global properties
    this.setGlobalProperties({
      'app.version': GARNER_VERSION,
      'app.buildNumber': GARNER_BUILD_NUMBER,
      'app.sourceSha': GARNER_COMMIT_SHA,
      'env.language': navigator.language,
    });
  }

  /**  Register global properties for analytics */
  setGlobalProperties(props: UserTrackingProperties) {
    if (this.isDisabled) return;
    Object.assign(this.globalProperties, props);
  }

  reset() {
    if (this.isDisabled) return;
    this.analytics.reset().catch(err => this.log.error({ err }, 'Error resetting analytics client'));
    this.initialize();
  }

  /** Track an event in segment with given props */
  track(eventName: Event, props?: UserTrackingProperties) {
    if (this.isDisabled) return;
    this.analytics
      .track(eventName, this.getAllProps(props))
      .catch(err => this.log.error({ err, strEventName: eventName, context: props }, 'Error tracking analytics event'));
  }

  /** Track an error in segment */
  trackError(name: Errors, message?: string) {
    this.track(Event.RECEIVE_ERROR, { errorName: name, errorMessage: message });
  }

  identify(id: string) {
    if (this.isDisabled) return;
    this.analytics.identify(id);
  }

  async authSegment() {
    const { careNavigatorId, clientId, email } = await getTokenClaims();
    this.identify(`${careNavigatorId}`);
    this.setGlobalProperties({ clientId, email });
  }
}

export const analytics = new Analytics(config.segment.token);
