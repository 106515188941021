import React from 'react';
import { FixedWidthLayout, Stack, Typography } from '@garner-health/components-common';
import { SR } from '@garner-health/lib-ui-content-management';

export const TieredNetworkCallout = () => {
  return (
    <FixedWidthLayout maxWidth="sm">
      <Stack alignItems="center" paddingY="md" spacing="xs">
        <Typography component="h2" variant="h4" fontWeight="bold">
          <SR package="search" id="tieredNetworkSearchHeaderText" />
        </Typography>
        <Typography variant="body1" color="common.muted" align="center">
          <SR package="search" id="tieredNetworkSearchInstructions" />
        </Typography>
      </Stack>
    </FixedWidthLayout>
  );
};
