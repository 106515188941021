function capitalize(str) {
    if (!str)
        return str;
    return `${str.charAt(0).toUpperCase()}${str.slice(1)}`;
}
/**
 * Helper function to make a simple-level record loggable, by prepending keys with the appropriate type-specific prefix.
 *
 * NB:
 * * Values that are anything but primitives or arrays of primitives will be excluded from the returned object.
 * * Values that are arrays will be assumed to contain the same types of values. eg `[1, '2', 'abc']` will be considered
 *   to be an array of numbers, and thus will fail to index.
 *
 * @param data Record to transform into a loggable type
 * @returns A loggable record, with loggable keys and the same values.
 *
 * @example
 * ```typescript
 * 'GET /providers': (req) {
 *   log.info(makeLoggable(req.query), 'Received request')
 *    ...
 * }
 * ```
 */
export function makeLoggable(data) {
    const logMessage = Object.entries(data).reduce((msg, [key, val]) => {
        if (val == null)
            return msg;
        const loggableKey = makeKeyLoggable(key, Array.isArray(val) ? val[0] : val);
        if (loggableKey == null)
            return msg;
        // We could do something like `return { ...msg,[loggableKey]: val }` but that's a lot of
        // object allocations just for a log message
        // @ts-ignore
        msg[loggableKey] = val;
        return msg;
    }, {});
    return logMessage;
}
function makeKeyLoggable(key, val) {
    const type = typeof val;
    switch (type) {
        case 'bigint':
        case 'number':
            return `num${capitalize(key)}`;
        case 'boolean':
            return `is${capitalize(key)}`;
        case 'string':
        case 'symbol':
            return `str${capitalize(key)}`;
        case 'object':
            if (val instanceof Date)
                return `date${capitalize(key)}`;
            return null;
        default:
            return null;
    }
}
export function enrichLogEvent(log, data) {
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (data == null)
        return log;
    const logEvent = { ...log, messages: [...log.messages] };
    if (log.messages[0] instanceof Error) {
        logEvent.messages = [{ ...data, err: log.messages[0] }, ...log.messages.slice(1)];
    }
    else if (typeof log.messages[0] === 'object') {
        logEvent.messages = [{ ...data, ...log.messages[0] }, ...log.messages.slice(1)];
    }
    else {
        logEvent.messages = [data, ...log.messages];
    }
    return logEvent;
}
