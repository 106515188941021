import langs from 'langs';
import logger from '~/logging';

const languageNameResolver = new Intl.DisplayNames(['en'], { type: 'language' });

const log = logger(__filename);

/** Maps an ISO 639-3 language code to a human readable name */
export function getLanguageName(languageCode: string): string | null {
  try {
    const name = langs.where('3', languageCode)?.name || languageNameResolver.of(languageCode);
    // `Intl.DisplayNames` will return the original code in some cases (presumably when it doesn't know what the code is)
    // so lets return null rather than a ISO code
    if (!name || name === languageCode) return null;
    return name;
  } catch (err) {
    log.error({ err, strLanguageCode: languageCode }, 'Error mapping language code to name');
    return null;
  }
}
