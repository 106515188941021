import React from 'react';
import { toCamel } from 'ts-case-convert';
import {
  Box,
  KeyValueItem,
  KeyValueList,
  Metric,
  Rating,
  ScrollingTabs,
  Stack,
  Tab,
  Typography,
} from '@garner-health/components-common';
import {
  interpolate,
  ResourcePackages,
  SR,
  StringValueKey,
  useStringResource,
} from '@garner-health/lib-ui-content-management';
import { Metric as IMetric, Professional } from '~/clients';
import { useMethodology, useProviderMetricMetadata, useProviderMetrics, useSpecialtyLabels } from '~/contexts';
import logger from '~/logging';
import { capitalize, capitalizeFirstLetter, getLanguageName, roundToDecimals } from '~/util';
import { useProviderDetails } from '~/contexts/provider-detail-context';
import { LocationHeader } from './location-header';
import { ProviderLocation } from './provider-location';

export interface ProfessionalDetailsProps {
  professional: Professional;
  metrics: IMetric[];
  displayQualityTab: boolean;
}

interface ProfessionalQualityDetailsProps {
  professional: Professional;
  overallScore: number;
  metrics: IMetric[];
  specialty: string;
}
const metricToValue: Record<string, 4 | 5 | 6 | undefined> = {
  good: 4,
  very_good: 5,
  excellent: 6,
};

const log = logger(__filename);

const OverallScore = ({ overallScore }: { overallScore: number }) => (
  <Stack.Group>
    <Typography variant="h5" component="h4" fontWeight="medium">
      <SR package="providerDetails" id="overallScoreLabel" />
    </Typography>
    <Typography fontWeight="medium" variant="h1" color="garner.primary.dark" component="h5">
      {overallScore}
    </Typography>
  </Stack.Group>
);

const MetricComparisonSentence = ({ metric }: { metric: { id: string; value: string } }) => (
  <SR
    package="providerDetails"
    id={`${toCamel(metric.value)}ComparisonSentence` as StringValueKey<ResourcePackages['providerDetails']>}
    interpolate={v => (
      <Typography variant="body1" color="primary.main" inline>
        {v}
      </Typography>
    )}
  />
);

const Metrics = ({ lastName, metrics }: { lastName: string; metrics: IMetric[] }) => {
  const providerMetrics = useProviderMetrics();
  const providerMetricMetadata = useProviderMetricMetadata();

  return (
    <Stack.Group>
      <Typography variant="h5" component="h4" fontWeight="medium">
        <SR package="providerDetails" id="metricsLabel" />
      </Typography>
      <Stack>
        {metrics.map(metric => {
          const value = metricToValue[metric.value];
          if (!value) {
            log.warn({ strValue: metric.id }, 'Invalid metric value, ignoring');
            return null;
          }
          const metricDetails = providerMetrics.get(metric.id);
          const metricMetadata = providerMetricMetadata.get(metric.value);
          if (!metricDetails || !metricMetadata) {
            log.warn(
              { metricId: metric.id, strValue: metric.value },
              'Metric or metric metadata not configured in CMS',
            );
            return null;
          }

          return (
            <Metric
              key={metric.id}
              label={interpolate(metricDetails.title, () => capitalize(metricMetadata.qualifier)) as string}
              labelComponent="h5"
              value={value}
              info={interpolate(metricDetails.description, tag => {
                if (tag === 'doctorShortName') return `Dr. ${lastName}`;
                if (tag === 'comparison_sentence') return <MetricComparisonSentence metric={metric} />;
              })}
            />
          );
        })}
      </Stack>
    </Stack.Group>
  );
};

const ProfessionalQualityDetailsBody = ({
  professional,
  overallScore,
  metrics,
  specialty,
}: ProfessionalQualityDetailsProps) => {
  const ratingCaption = useStringResource('providerDetails', 'reviewStarsCaption', {
    stars: () => roundToDecimals(professional.reviewStars!, 1),
  });
  const methodology = useMethodology(specialty);
  return (
    <Stack spacing="lg">
      <OverallScore overallScore={overallScore} />
      {!!metrics.length && <Metrics lastName={professional.lastName} metrics={metrics} />}
      {professional.reviewStars != null && (
        <Stack spacing="lg">
          <>
            <Typography variant="h4">
              <SR package="providerDetails" id="patientReviewsLabel" />
            </Typography>
            <Rating rating={professional.reviewStars} caption={ratingCaption} />
          </>
          {methodology && (
            <Stack spacing="xs">
              <Typography fontWeight="medium" component="h3" variant="h4">
                <SR package="providerDetails" id="methodologyLabel" />
              </Typography>
              <Typography variant="body1">{methodology}</Typography>
            </Stack>
          )}
        </Stack>
      )}
    </Stack>
  );
};

const ProfessionalQualityDetails = ({ metrics }: { metrics: IMetric[] }) => {
  const details = useProviderDetails();
  if (!details?.professional) return;
  const { professional, specialty } = details;
  const fullSpecialty = professional.specialties.get(specialty);
  if (!fullSpecialty) {
    log.error({ professionalId: professional.id, strSpecialty: specialty }, 'specialty not found in professional');
    return;
  }
  return (
    <ProfessionalQualityDetailsBody
      professional={professional}
      overallScore={fullSpecialty.overallScore}
      metrics={metrics}
      specialty={specialty}
    />
  );
};

const ProfessionalDetailsBody = ({ professional, metrics, displayQualityTab }: ProfessionalDetailsProps) => {
  const qualitySectionTitle = useStringResource('providerDetails', 'qualitySectionTitle');
  const aboutSectionTitle = useStringResource('providerDetails', 'aboutSectionTitle');
  const languages = professional.languages?.map(getLanguageName).filter(Boolean);

  const specialtyLabels = useSpecialtyLabels(Array.from(professional.specialties.keys()));
  const aboutProfessionalItems = [
    { key: useStringResource('providerDetails', 'aboutSectionNpiLabel'), value: professional.npi },
    {
      key: useStringResource('providerDetails', 'aboutSectionGenderLabel'),
      value: capitalizeFirstLetter(professional.sex),
    },
    {
      key: useStringResource('providerDetails', 'aboutSectionLanguagesLabel'),
      value: professional.languages ? languages : null,
    },
    {
      key: useStringResource('providerDetails', 'aboutSectionCredentialsLabel'),
      value: professional.credentials,
    },
    {
      key: useStringResource('providerDetails', 'aboutSectionSpecialtiesLabel'),
      value: specialtyLabels.length ? specialtyLabels : null,
    },
  ].filter(item => item.value) as KeyValueItem[];

  return (
    <ScrollingTabs>
      {displayQualityTab && (
        <Tab label={qualitySectionTitle}>
          <Box paddingBottom="md">
            <Typography variant="h3">{qualitySectionTitle}</Typography>
          </Box>
          <ProfessionalQualityDetails metrics={metrics} />
        </Tab>
      )}

      {!!aboutProfessionalItems.length && (
        <Tab label={aboutSectionTitle}>
          <Box paddingBottom="md">
            <Typography variant="h3">{aboutSectionTitle}</Typography>
          </Box>
          <KeyValueList items={aboutProfessionalItems} />
        </Tab>
      )}

      <Tab label={useStringResource('providerDetails', 'locationSectionTitle')}>
        <LocationHeader />
        <ProviderLocation />
      </Tab>
    </ScrollingTabs>
  );
};

export const ProfessionalDetails = ({ metricIds }: { metricIds: string[] }) => {
  const details = useProviderDetails();
  if (!details) return;
  const { professional, locationId, specialty, networkId } = details;
  if (!professional) return;

  const metrics = professional.metrics.filter(metric => metricIds.includes(metric.id));
  const currentSpecialty = professional.specialties.get(specialty);
  const specialtyLocation = currentSpecialty?.locations.find(elem => elem.locationId === locationId);
  const isTopProvider = specialtyLocation?.networks.find(n => n.networkId === networkId)?.isTopProvider;
  const displayQualityTab =
    !!isTopProvider && !!currentSpecialty && !!(metrics.length || professional.reviewStars != null);

  return (
    <ProfessionalDetailsBody professional={professional} metrics={metrics} displayQualityTab={displayQualityTab} />
  );
};
