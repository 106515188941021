/* eslint-disable no-console */
const launchDarklyApiUrl = 'https://app.launchdarkly.com/api/v2';
export async function updateFlags({ projectKey, apiKey, flags, }) {
    if (process.env.NODE_ENV === 'production')
        throw new Error('Cannot run script in a production environment');
    try {
        for (const flag of flags) {
            const resp = await fetch(`${launchDarklyApiUrl}/flags/${encodeURIComponent(projectKey)}/${encodeURIComponent(flag.key)}`, { method: 'GET', headers: { Authorization: apiKey } });
            if (resp.status === 200)
                continue;
            if (resp.status !== 404) {
                console.log('Error looking up feature flag', resp);
                return;
            }
            const createFlagResponse = await fetch(`${launchDarklyApiUrl}/flags/${encodeURIComponent(projectKey)}`, {
                method: 'POST',
                headers: { 'Content-Type': 'application/json', Authorization: apiKey },
                body: JSON.stringify({
                    ...flag,
                    clientSideAvailability: { usingEnvironmentId: true },
                }),
            });
            if (createFlagResponse.status !== 201) {
                console.error('Error creating flag', createFlagResponse);
                return;
            }
        }
        console.log('Done');
    }
    catch (err) {
        console.error('Error updating feature flags', err);
    }
}
