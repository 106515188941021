import { HTTP, HTTPResponse } from '@ionic-native/http';
import * as C from '../core';
import { hasResponseBody } from './common';

function toLowerCase<T extends string>(str: T): Lowercase<T> {
  return str.toLowerCase() as any;
}

function asArrayBuffer(blob: Blob) {
  return new Promise<ArrayBuffer>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = () => resolve(reader.result as ArrayBuffer);
    reader.onerror = () => reject(reader.error);
    reader.readAsArrayBuffer(blob);
  });
}

type SendRequestOptions = Parameters<typeof HTTP.sendRequest>[1];

async function getRequestBody(body: C.Body): Promise<Partial<SendRequestOptions> | undefined> {
  if ('body' in body) {
    return {
      serializer: 'raw',
      data: await asArrayBuffer(body.body),
    };
  }

  if ('json' in body) {
    return {
      serializer: 'json',
      data: body.json as any,
    };
  }

  return undefined;
}

function getResponseBody(headers: C.Headers, data: any): C.Body | undefined {
  if (!hasResponseBody(headers['content-length'], headers['transfer-encoding'])) {
    return undefined;
  }

  return C.isJsonMedia(headers['content-type']) ? { json: data } : { body: new Response(data) };
}

// eslint-disable-next-line no-control-regex
const headerMatcher = /^([^\x00-\x1f\x7f()<>@,;:\\"/[\]?={} \t]*):\s*([^\x00-\x1f\x7f]*)\s*$/gm;
/**
 * Parses headers and returns headers with normalized, or all lower case keys.
 * @param headers Headers can be both string or object depending on system platform unfortunately.
 * On ionic web, the headers come back as a string, but on ios and android, they come back as object.
 * @returns Normalized headers
 */
function parseHeaders(headers?: string | Record<string, any> | null): C.Headers {
  if (!headers) return {};

  const entries: [string, string][] = [];

  if (typeof headers === 'string') {
    for (const [, key, value] of headers.matchAll(headerMatcher)) {
      entries.push([key, value]);
    }
    return C.normalizeHeaders(entries);
  } else {
    return C.normalizeHeaders(Object.entries(headers));
  }
}

export const ionicAdapter: C.NetworkAdapter = async req => {
  const response = await HTTP.sendRequest(req.url, {
    method: toLowerCase(req.method),
    headers: req.headers as Record<string, string> | undefined,
    ...(await getRequestBody(req)),
    responseType: C.isJsonMedia(req.headers?.accept) ? 'json' : 'blob',
  }).catch(err => {
    // If the error has a status, it's just a non-200 response. Let axios decide to reject it consistently instead.
    if (err.status) {
      // When there's a non 2xx response, cordova-plugin-advanced-http basically gives up and barfs a very raw
      // response on us. Super rude.
      const headers = parseHeaders(err.headers);
      return {
        status: err.status,
        url: err.url,
        error: err,
        headers,
        // NOTE: if the response is anything other than JSON, _THIS WILL NOT WORK!_
        // Don't blame me, blame the shitty implementation over at cordova-plugin-advanced-http.
        data: C.isJsonMedia(headers['content-type']) ? JSON.parse(err.error) : null,
      } as HTTPResponse;
    }

    return Promise.reject(err);
  });

  const headers = C.normalizeHeaders(Object.entries(response.headers));

  return {
    url: response.url,
    status: response.status,
    headers,
    ...getResponseBody(headers, response.data),
  };
};
