import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Network } from '~/clients';
import { useNetworks } from '~/contexts';
import logger from '~/logging';
import { deserializePosition, fromSearchParams, toSearchParams } from '~/util';
import { hasSameValues, SearchFormData } from '~/components/provider-search';
import { usePrevious } from '~/hooks/use-previous';
import { CareGoalSelectOption, getCareGoals } from '~/components/provider-search/common';
import { Gender, languageOptions, useGenderOptions } from '~/components/provider-search/select-options';

const log = logger(__filename);

export interface ProviderSearchParams {
  careGoalId: string | null;
  providerName: string | null;
  networkId: string | null;
  location: { label: string; value: string } | null;
  language: string | null;
  gender: string | null;
}

function getProviderSearchParams(searchParams: URLSearchParams): ProviderSearchParams {
  const locationLabel = searchParams.get('locationLabel');
  const locationValue = searchParams.get('locationValue');

  return {
    careGoalId: searchParams.get('careGoalId'),
    providerName: searchParams.get('providerName'),
    networkId: searchParams.get('networkId'),
    location: locationLabel && locationValue ? { label: locationLabel, value: locationValue } : null,
    language: searchParams.get('language'),
    gender: searchParams.get('gender'),
  };
}

function validateParamsList({
  providerSearchParams: { careGoalId, providerName, networkId, location, gender, language },
  networks,
  genderOptions,
}: {
  providerSearchParams: ProviderSearchParams;
  networks: Network[];
  genderOptions: { value: Gender }[];
}) {
  if ((!careGoalId && !providerName) || !networkId || !location) {
    // Only log the message if any of the params are populated
    if (careGoalId || providerName || networkId || location || gender || language) {
      log.debug(
        {
          careGoalId,
          strProviderName: providerName,
          networkId,
          strLocationLabel: location?.label,
          strLocationValue: location?.value,
        },
        'Missing a required search param, ignoring all params',
      );
    }
    return null;
  }

  const network = networks.find(n => n.id === networkId);
  if (!network) {
    log.debug({ networkId }, 'Invalid network ID in query, ignoring all params');
    return null;
  }

  if (gender && !genderOptions.find(g => g.value === gender)) {
    log.debug({ strGender: gender }, 'Invalid gender in query, ignoring all params');
    return null;
  }

  if (language && !languageOptions.find(l => l.value === language)) {
    log.debug({ strLanguage: language }, 'Invalid language in query, ignoring all params');
    return null;
  }
  return { careGoalId, providerName, network, location, gender, language };
}

/** Validate search params other than careGoal and location */
//TODO: refactor this to useValidatedSearchParams and don't pass in networks or gender options
// https://app.asana.com/0/1203374383582495/1205127243912397/f
export async function validateSearchParams({
  providerSearchParams: { careGoalId, providerName, networkId, location, gender, language },
  networks,
  genderOptions,
}: {
  providerSearchParams: ProviderSearchParams;
  networks: Network[];
  genderOptions: { value: Gender }[];
}): Promise<SearchFormData | null> {
  const params = validateParamsList({
    providerSearchParams: { careGoalId, providerName, networkId, location, gender, language },
    networks,
    genderOptions,
  });
  if (!params) return null;

  let careGoalResponse: CareGoalSelectOption[] = [];
  try {
    if (careGoalId) {
      careGoalResponse = await getCareGoals({ id: careGoalId });
    }
  } catch (err) {
    log.error({ err, careGoalId }, 'Care goal not found when loading from URL search params');
    return null;
  }

  if (careGoalId && !careGoalResponse.length) {
    log.error({ careGoalId }, 'Care goal not found when loading from URL search params');
    return null;
  }

  const position = deserializePosition(params.location.value);
  if (!position) {
    log.error({ strValue: params.location.value }, 'Invalid lat/lng when loading from URL search params');
    return null;
  }

  return {
    careGoal: careGoalResponse[0] ?? null,
    providerName,
    location: {
      ...params.location,
      position,
    },
    network: {
      label: params.network.name,
      value: params.network.id,
    },
    language,
    gender: gender ? (gender as Gender) : null,
  };
}

export function useSearchFormData() {
  const [searchParams, setSearchParams] = useSearchParams();
  const networks = useNetworks();
  const genderOptions = useGenderOptions();
  const [formData, setFormData] = useState<SearchFormData | null>(null);
  const [loading, setLoading] = useState(true);
  const previousFormData = usePrevious<SearchFormData | null>(formData);

  const providerSearchParams = getProviderSearchParams(searchParams);

  function onNewFormData(data: SearchFormData) {
    setFormData(data);

    setSearchParams(prev =>
      toSearchParams({
        ...fromSearchParams(prev),
        careGoalId: data.careGoal?.value,
        providerName: data.providerName,
        locationLabel: data.location.label,
        locationValue: data.location.value,
        networkId: data.network.value,
        gender: data.gender,
        language: data.language,
      }),
    );
  }

  useEffect(() => {
    (async () => {
      setLoading(true);
      const validatedFormData = await validateSearchParams({
        networks,
        genderOptions,
        providerSearchParams,
      });
      setLoading(false);
      if (validatedFormData && formData && hasSameValues(formData, validatedFormData)) {
        return;
      }
      if (!validatedFormData) {
        setFormData(null);
        return;
      }
      setFormData(validatedFormData);
    })();
  }, [JSON.stringify(providerSearchParams)]);

  return {
    data: formData,
    loading,
    onNewFormData,
    shouldReset: previousFormData !== null && formData === null,
  };
}
