export class Cache {
    constructor(ttl) {
        // https://jsbench.me/n1li92zswi/1
        this.cache = new Map();
        this.ttl = ttl;
    }
    setValue(key, value) {
        this.cache.set(key, {
            exp: Date.now() + this.ttl,
            value,
        });
    }
    getValue(key) {
        var _a;
        const entry = this.cache.get(key);
        if (entry && this.isExpired(entry)) {
            this.cache.delete(key);
            return null;
        }
        return (_a = entry === null || entry === void 0 ? void 0 : entry.value) !== null && _a !== void 0 ? _a : null;
    }
    reset() {
        this.cache.clear();
    }
    isExpired(entry) {
        var _a;
        return ((_a = entry.exp) !== null && _a !== void 0 ? _a : 0) <= Date.now();
    }
}
