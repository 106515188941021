import React, { useEffect, useState } from 'react';
import constants from 'app-constants';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  ExternalLink,
  Form,
  Spinner,
  Typography,
} from '@garner-health/components-common';
import { SR, useStringResource } from '@garner-health/lib-ui-content-management';
import { analytics, Event } from '~/analytics';
import { CareNavigator, careNavigatorClient } from '~/clients';
import { useCareNavigator } from '~/contexts';
import logger from '~/logging';

interface FormData {
  dataSecurityReminderCheckbox: boolean;
}

const log = logger(__filename);

// Exported for testing
export function shouldReAttestToDataSecurityAgreement(careNavigator: CareNavigator): boolean {
  if (!careNavigator.dataSecurityAgreement) {
    log.debug('No previous data agreement, showing dialog');
    return true;
  }

  const diffMs = Date.now() - careNavigator.dataSecurityAgreement.createdAt.getTime();
  if (diffMs > constants.dataSecurityAttestationFrequencyMs) {
    log.debug(
      { numDiffMs: diffMs, numThresholdMs: constants.dataSecurityAttestationFrequencyMs },
      'Showing data security attestation',
    );
    return true;
  }

  return false;
}

export const DataSecurityDialog = () => {
  const { careNavigator, setCareNavigator } = useCareNavigator();
  const form = useForm<FormData>();
  const [isOpen, setIsOpen] = useState(false);
  const [isAttestationLoading, setIsAttestationLoading] = useState(false);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const dataSecurityReminderCheckbox = form.watch('dataSecurityReminderCheckbox');

  useEffect(() => {
    if (shouldReAttestToDataSecurityAgreement(careNavigator)) {
      setIsOpen(true);
    }
  }, []);

  useEffect(() => {
    setIsCheckboxChecked(dataSecurityReminderCheckbox);
  }, [dataSecurityReminderCheckbox]);

  function onClose(updatedCareNav: CareNavigator) {
    setCareNavigator({
      ...updatedCareNav,
      dataSecurityAgreement: updatedCareNav.dataSecurityAgreement,
    });
    setIsOpen(false);
  }

  function formSubmitHandler(data: FormData) {
    analytics.track(Event.DATA_SECURITY_AGREEMENT);

    setIsAttestationLoading(true);
    careNavigatorClient
      .updateCareNavigator(careNavigator.id, {
        agreedToDataSecurityAgreement: data.dataSecurityReminderCheckbox as true,
      })
      .then(response => {
        if (!response.success) {
          log.error({ careNavigatorId: careNavigator.id }, 'Failed to save agreement to data security agreement');
        } else {
          onClose(response.data);
        }
      })
      .catch(err => {
        log.error({ err }, 'Unexpected error agreeing to data security prompt');
        onClose(careNavigator);
      })
      .finally(() => {
        setIsCheckboxChecked(false);
        setIsAttestationLoading(false);
      });
  }
  const termsOfServiceLink = useStringResource('main', 'termsOfServiceUrl');

  return (
    <Dialog
      // We're intentionally not closing the modal when `onClose` fires because we don't want the user to be able
      // to navigate away without clicking continue
      open={isOpen}
      title={useStringResource('search', 'dataSecurityReminderTitle')}
      body={
        <Form form={form} onSubmit={formSubmitHandler} id="data-security-form">
          <Typography variant="h4">
            <SR
              package="search"
              id="dataSecurityReminderBodyText"
              interpolate={() => (
                <ExternalLink
                  href={termsOfServiceLink}
                  color="primary.main"
                  onClick={() => analytics.track(Event.TERMS_OF_SERVICE)}
                >
                  <SR package="search" id="termsOfService" />
                </ExternalLink>
              )}
            />
          </Typography>
          <Typography variant="body1">
            <SR package="search" id="dataSecurityReminderBodySubtext" />
          </Typography>
          <Box paddingY="xs">
            <Checkbox
              name="dataSecurityReminderCheckbox"
              label={useStringResource('search', 'dataSecurityReminderCheckboxText')}
            />
          </Box>
        </Form>
      }
      footer={
        <Button type="submit" disabled={isAttestationLoading || !isCheckboxChecked} form="data-security-form">
          {isAttestationLoading ? (
            <Spinner variant="dark" />
          ) : (
            <SR package="search" id="agreeToDataSecurityModalContinueButtonText" />
          )}
        </Button>
      }
    />
  );
};
