export const overallScoreLabel = 'Overall Score';
export const metricsLabel = 'Metrics';
export const shareCardLabel = 'Share';
export const patientReviewsLabel = 'Patient reviews';
export const aboutSectionNpiLabel = 'NPI';
export const aboutSectionGenderLabel = 'Gender';
export const aboutSectionLanguagesLabel = 'Language(s)';
export const aboutSectionCredentialsLabel = 'Credentials';
export const aboutSectionSpecialtiesLabel = 'Specialties';
export const qualitySectionTitle = 'Quality';
export const aboutSectionTitle = 'About';
export const locationSectionTitle = 'Location';
export const reviewStarsCaption = '{stars:stars} stars';
export const providerAvatarAltText = 'Provider image';
export const shareCardFirstNameLabel = 'First name';
export const shareCardLastNameLabel = 'Last name';
export const shareCardFacilityNameLabel = 'Facility name';
export const shareCardPhoneNumberLabel = 'Phone number';
export const shareCardAddressLabel = 'Address';
export const copyToClipboardButtonText = 'Copy to clipboard';
export const suggestAnEdit = 'Suggest an edit';
export const reportLocation = 'Report another location';
export const hoursLabel = 'Hours';
export const dayOneText = 'Monday';
export const dayTwoText = 'Tuesday';
export const dayThreeText = 'Wednesday';
export const dayFourText = 'Thursday';
export const dayFiveText = 'Friday';
export const daySixText = 'Saturday';
export const daySevenText = 'Sunday';
export const referralCopiedToClipboardLabel = 'Referral';
export const fieldCopiedToClipboardText = '{field:field} copied to clipboard';
export const excellentComparisonSentence = 'performs in the {top 20%} among their local doctors on';
export const veryGoodComparisonSentence = 'performs {better than local doctors}';
export const goodComparisonSentence = '{exceeds the clinical threshold} set by specialists';
export const facilityText = 'Facility';
export const physicianText = 'Physician';
export const locationSectionAddressAriaLabelText = 'Address';
export const locationSectionFaxNumberAriaLabelText = 'Fax number';
export const locationSectionPhoneNumberAriaLabelText = 'Phone number';
export const locationSectionLimitedAvailabilityAriaLabelText = 'Limited availability for new patients';
export const notPreferredAlertTitle = 'Not preferred';
export const notPreferredAlertBody = 'This provider is not preferred for recommendations';
export const methodologyLabel = `Garner's methodology`;
