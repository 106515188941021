import pino from 'pino';
// Stolen from https://github.com/axios/axios/blob/6fca6a7027caeb4c0c7d0305ab4182bfd8a65536/lib/helpers/isAxiosError.js#L12
function isAxiosError(err) {
    return err?.isAxiosError === true;
}
export const serializeError = pino.stdSerializers.wrapErrorSerializer(serialized => {
    /* eslint-disable @typescript-eslint/no-unnecessary-condition */
    // Pino does some weird stuff that disconnects the error from its prototype. Use .raw to get the OG error
    const err = serialized?.raw;
    if (isAxiosError(err)) {
        const req = err.request; // narrowing the type
        return {
            message: err.message,
            stack: err.stack,
            req: {
                method: req.method,
                url: req.path,
                hostname: req.host,
                // TODO: Add tests
                headers: req?.getHeaders?.(),
            },
            res: {
                headers: err.response?.headers,
                statusCode: err.response?.status,
                data: err.response?.data ? err.response.data : undefined,
            },
        };
    }
    return serialized;
    /* eslint-enable */
});
