// Login Page
export const headingText = 'Sign in';
export const continueText = 'Continue';
export const loginText = 'Login';
export const forgotPasswordText = 'Forgot password';
export const emailLabel = 'Email';
export const passwordLabel = 'Password';
export const unknownErrorText = 'Error logging in. Please reach out to support';
export const notAuthorizedErrorText = 'Invalid login credentials';
export const notAuthorizedSsoErrorText = 'You do not have access to DataPro. Please reach out to support';

// Forgot Password Page
export const forgotPasswordHeadingText = 'Forgot password';
export const sendResetText = 'Send reset password link';
export const loginPageText = 'Login';
export const sentResetLinkText = 'Check your email for a password reset link';
