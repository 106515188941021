import { SelectOption } from '@garner-health/components-common';
import { geocodeClient, r12nClient, SupportedCareGoalType, UnsupportedCareGoalType } from '~/clients';
import { Position, serializePosition } from '~/util';

interface NotSupportedCareGoalSelectOption extends SelectOption {
  metricIds: (string | null)[];
  type: UnsupportedCareGoalType;
}

interface SupportedCareGoalSelectOption extends SelectOption {
  specialty: string;
  metricIds: (string | null)[];
  type: SupportedCareGoalType;
}

export type CareGoalSelectOption = SupportedCareGoalSelectOption | NotSupportedCareGoalSelectOption;

export interface LocationSelectOption extends SelectOption {
  position: Position;
}

export async function getCareGoals(input: { query: string } | { id: string }): Promise<CareGoalSelectOption[]> {
  return r12nClient.getCareGoals(input).then(careGoals =>
    careGoals.map(careGoal => {
      if (careGoal.type === 'not_supported') {
        return {
          label: careGoal.term,
          value: careGoal.careGoalId,
          metricIds: careGoal.metricIds,
          type: careGoal.type,
        };
      }
      return {
        label: careGoal.term,
        value: careGoal.careGoalId,
        specialty: careGoal.specialty,
        metricIds: careGoal.metricIds,
        type: careGoal.type,
      };
    }),
  );
}

export async function geocodeLocation(input: string): Promise<LocationSelectOption[]> {
  return geocodeClient.geocodeLocation(input).then(locations =>
    locations.map(location => ({
      label: location.formattedAddress,
      value: serializePosition(location.position),
      position: location.position,
    })),
  );
}
