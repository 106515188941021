export enum Event {
  CARE_GOAL_SEARCH = 'Care goal search',
  NAME_SEARCH = 'Name Search',
  CARE_GOAL_AND_NAME_SEARCH = 'Care goal and name search',
  CARE_GOAL_SUPPORT = 'Care goal support',
  CLICK_BUTTON = 'Click button',
  COPY_CARD_TO_CLIPBOARD = 'Copy card to clipboard',
  COPY_FIELD_TO_CLIPBOARD = 'Copy single field to clipboard',
  DATA_SECURITY_AGREEMENT = 'Data security agreement',
  EDIT_PROFILE = 'Edit profile',
  FILTER_GENDER = 'Filter gender',
  FILTER_LANGUAGE = 'Filter language',
  FORGOT_PASSWORD = 'Forgot password',
  GENERAL_SUPPORT = 'General support',
  NAVIGATE = 'Navigate',
  PRIVACY_STATEMENT = 'Privacy statement clicked',
  RECEIVE_ERROR = 'Receive error',
  RESET_PASSWORD = 'Reset password',
  SIGN_IN = 'Sign in',
  SIGN_OUT = 'Sign out',
  SUGGEST_EDIT = 'Suggest edits',
  TERMS_OF_SERVICE = 'Terms of service clicked',
}

export enum Errors {
  DEFAULT_ERROR = 'Default error',
  UNKNOWN_ERROR = 'Unknown error',
}
