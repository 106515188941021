import { useStringResource } from '@garner-health/lib-ui-content-management';

export type Gender = 'male' | 'female';

export const useGenderOptions = (): { value: Gender; label: string }[] => [
  {
    label: useStringResource('search', 'female'),
    value: 'female',
  },
  {
    label: useStringResource('search', 'male'),
    value: 'male',
  },
];

export const languageOptions = [
  { label: 'Arabic', value: 'ara' },
  { label: 'Chinese', value: 'zho' },
  { label: 'English', value: 'eng' },
  { label: 'Filipino', value: 'fil' },
  { label: 'French', value: 'fra' },
  { label: 'German', value: 'deu' },
  { label: 'Hindi', value: 'hin' },
  { label: 'Korean', value: 'kor' },
  { label: 'Russian', value: 'rus' },
  { label: 'Spanish', value: 'spa' },
  { label: 'Vietnamese', value: 'vie' },
];
