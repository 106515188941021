import { RecommendationServiceV1 } from '@garner-health/api-client/r12n-v1';
import { RecommendationServiceV2 } from '@garner-health/api-client/r12n-v2';
import { getScopeInfo, getTokenClaims } from '~/auth';
import createLogger from '~/logging';
import { Position, removeEmptyFields } from '~/util';
import { baseClientOptions, getDefaultHeaders } from './common';

const log = createLogger(__filename);

export type SupportedCareGoalType = 'specialty' | 'procedure' | 'symptom' | 'condition';
export type UnsupportedCareGoalType = 'not_supported';
export type CareGoalType = SupportedCareGoalType | UnsupportedCareGoalType;
export type ProviderType = 'physician' | 'facility';

/**
 * The type of the providers list. Determines which fields to include on table and how table can be sorted
 * @enum facility
 * @enum physician
 * @enum name
 */
export type ProvidersResponseSetType = ProviderType | 'name';

export interface SupportedCareGoal {
  term: string;
  type: SupportedCareGoalType;
  careGoal: string;
  careGoalId: string;
  specialty: string;
  metricIds: (string | null)[];
}

export interface UnsupportedCareGoal {
  term: string;
  type: UnsupportedCareGoalType;
  careGoal: string;
  careGoalId: string;
  metricIds: (string | null)[];
}

// exported for testing
export type CareGoal = SupportedCareGoal | UnsupportedCareGoal;

export interface GetProvidersParams {
  specialty?: string;
  name?: string;
  networkId: string;
  location: Position;
  language?: string;
  gender?: 'male' | 'female';
  limit?: number;
  includeLowQualityProviders?: boolean;
  providerSearchSet?: 'in_network' | 'top_provider';
}

export interface GetProviderParams {
  id: string;
  locationId: string;
  specialty: string;
  networkId: string;
}

export interface Metric {
  id: string;
  value: string;
}

export interface ProviderResultItem {
  id: string;
  type: ProviderType;
  firstName?: string;
  lastName?: string;
  organizationName?: string;
  specialty: string;
  distanceMi?: number;
  overallScore?: number;
  location: {
    id: string;
    position: Position;
  };
  specialties: string[];
  isTopProvider?: boolean;
  metrics?: Metric[];
}

interface Location {
  id: string;
  name?: string;
  lines: string[];
  city: string;
  state: string;
  zipCode: string;
  position: Position;
}

export interface Hours {
  day: 1 | 2 | 3 | 4 | 5 | 6 | 7; // 1 == Monday
  open: number;
  close: number;
}

interface ProviderBase {
  id: string;
  npi: string;
  specialty: string;
  location: Location;
  phoneNumber: string;
  faxNumber?: string;
  hours: Hours[];
  allSpecialties: string[];
  networkId: string;
  isTopProvider?: boolean;
}

export interface R12nFacility extends ProviderBase {
  type: 'facility';
  organizationName: string;
}

export interface Physician extends ProviderBase {
  type: 'physician';
  firstName: string;
  lastName: string;
  languages: string[];
  gender: 'male' | 'female';
  metrics: Metric[];
  credentials?: string;
  acceptsNewPatients?: boolean;
  overallScore?: number;
  reviewStars?: number;
}

export type Provider = R12nFacility | Physician;

class R12nClient {
  private v1Client: RecommendationServiceV1;
  private v2Client: RecommendationServiceV2;

  constructor() {
    this.v1Client = new RecommendationServiceV1(baseClientOptions);
    this.v2Client = new RecommendationServiceV2(baseClientOptions);
  }

  async getCareGoals(input: { query: string } | { id: string }): Promise<CareGoal[]> {
    const query = 'query' in input ? input : { id: [input.id] };

    return this.v1Client
      .get('/care-goals', {
        headers: await getDefaultHeaders(),
        query: { ...query, language: 'en-US' },
      })
      .onStatus(200, r => r.json.careGoals)
      .then(careGoals => {
        return careGoals.map(careGoal => {
          const metricIds = careGoal.metricIds.map(id => id || null);
          if (careGoal.type === 'not_supported') return { ...careGoal, metricIds, type: careGoal.type };

          return {
            ...careGoal,
            metricIds,
            type: careGoal.type,
            specialty: careGoal.specialty!,
          };
        });
      })
      .catch(err => {
        log.error({ err, context: input }, 'Error getting care goals');
        throw Error('Error getting care goals');
      });
  }

  async getProviders({
    specialty,
    name,
    networkId,
    language,
    gender,
    location: { lat, lng },
    includeLowQualityProviders,
    providerSearchSet,
    limit,
  }: GetProvidersParams): Promise<{ providers: ProviderResultItem[]; responseSetType?: ProvidersResponseSetType }> {
    const query = removeEmptyFields({
      specialty,
      name,
      networkId,
      lat,
      lng,
      plan: await this.getPlan(),
      language,
      gender,
      includeLowQualityProviders,
      providerSearchSet,
      limit,
    });
    return await this.v1Client
      .get('/providers', {
        headers: await getDefaultHeaders(),
        query,
      })
      .onStatus(200, r => {
        // If empty response, return as is
        if (r.json.providers.length === 0) return { providers: [] };

        const responseSetType: ProvidersResponseSetType = name ? 'name' : r.json.providers[0].type;

        // If facilities, don't collapse list
        if (responseSetType === 'facility') {
          return {
            providers: r.json.providers.map(provider => ({
              ...provider,
              specialties: [provider.specialty],
            })),
            responseSetType,
          };
        }

        const providers = new Map<string, ProviderResultItem[]>();
        r.json.providers.forEach(provider => {
          if (!providers.has(provider.id) && provider.networkStatus) providers.set(provider.id, []);
          providers.get(provider.id)?.push({ ...provider, specialties: [provider.specialty] });
        });

        // If all providers are unique, return
        if (providers.size === r.json.providers.length) {
          return { providers: [...providers.values()].flat(), responseSetType };
        }

        // If there are multiple documents per provider,
        // coalesce them into one provider item with data from the closest document and a list of all specialties
        const result: ProviderResultItem[] = [];
        providers.forEach(providerList => {
          const provider = providerList.reduce((accumulated, current) => {
            if (!accumulated.specialties.includes(current.specialty)) accumulated.specialties.push(current.specialty);
            return accumulated;
          });
          result.push(provider);
        });
        return { providers: result, responseSetType };
      })
      .onStatus(422, r => {
        log.error(
          {
            req: { id: r.json.requestId },
            strSpecialty: specialty,
            networkId,
            numLat: lat,
            numLng: lng,
            strLanguage: language,
            strGender: gender,
            message: r.json.message,
          },
          'Bad request when getting providers',
        );
        return { providers: [] };
      })
      .catch(err => {
        log.error(
          {
            err,
            strSpecialty: specialty,
            networkId,
            numLat: lat,
            numLng: lng,
            strLanguage: language,
            strGender: gender,
          },
          'Error getting providers',
        );
        throw err;
      });
  }

  private async getPlan(): Promise<'core' | 'core_plus'> {
    const { scope } = await getTokenClaims();
    return getScopeInfo(scope).plan;
  }
}

export const r12nClient = new R12nClient();
