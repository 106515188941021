import React, { createContext, useCallback, useContext, useEffect, useMemo } from 'react';
import { useLDClient, useFlags as useLaunchDarklyFlags, withLDProvider } from 'launchdarkly-react-client-sdk';
const FeatureFlagContext = createContext(null);
function useConfig() {
    const config = useContext(FeatureFlagContext);
    if (!config)
        throw new Error('Feature flag provider has not been initialized');
    return config;
}
/**
 * Provider for feature flags.
 * NB: This provider must be rendered at the root of your react application.
 *
 * @example
 * const WrappedApp = () => (
 *   <React.StrictMode>
 *     <FeatureFlagProvider config={featureFlagConfig}>
 *       <App />
 *     </FeatureFlagProvider>
 *   </React.StrictMode>
 * );
 * createRoot(document.getElementById('approot')!).render(<WrappedApp />);
 */
export const FeatureFlagProvider = ({ children, config }) => {
    const { log, clientId, appId, appVersion } = config;
    const ldLogger = useMemo(() => {
        const childLogger = log.child({ module: 'launchDarkly' });
        return {
            error: childLogger.error.bind(childLogger),
            warn: childLogger.warn.bind(childLogger),
            // Info and debug are very spammy and we generally don't care that much so lets lower them
            info: childLogger.debug.bind(childLogger),
            debug: childLogger.trace.bind(childLogger),
        };
    }, [log]);
    const WrappedChildren = withLDProvider({
        reactOptions: { useCamelCaseFlagKeys: false },
        clientSideID: clientId,
        options: {
            logger: ldLogger,
            streaming: true,
            application: { id: appId, version: appVersion },
        },
    })(() => children);
    return (React.createElement(FeatureFlagContext.Provider, { value: config },
        React.createElement(WrappedChildren, null)));
};
export function useFlags() {
    const ldFlags = useLaunchDarklyFlags();
    const { log, defaults, onFlagsChange } = useConfig();
    useEffect(() => {
        onFlagsChange(ldFlags);
    }, [ldFlags, onFlagsChange]);
    return new Proxy(ldFlags, {
        get(target, prop) {
            const value = target[prop];
            if (value === undefined) {
                const defaultValue = prop in defaults ? defaults[prop] : undefined;
                log.debug({ strProp: prop, strDefault: defaultValue === null || defaultValue === void 0 ? void 0 : defaultValue.toString() }, 'Feature flag is undefined when being fetched, falling back to default');
                return defaultValue;
            }
            return value;
        },
    });
}
export function useIdentifyForFeatureFlags() {
    const ldClient = useLDClient();
    const { log } = useConfig();
    return useCallback((identity) => {
        if (!ldClient) {
            log.warn({ context: identity }, 'Feature flag client is null, cannot identify user');
            return;
        }
        log.debug({ context: identity }, 'Initializing user with LaunchDarkly');
        ldClient
            .identify({ kind: 'user', ...identity })
            .catch(err => log.error(err, 'Error initializing feature flag user context'));
    }, [ldClient, log]);
}
