import React, { PropsWithChildren } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import createLogger from '~/logging';
import { ErrorPage } from '~/pages';
import { analytics, Errors } from './analytics';

const errorBoundaryLogger = createLogger('error-boundary');

function formatError(err: any) {
  try {
    return {
      ...err,
      stack: err?.stack,
      name: err?.name,
      message: err?.message,
    };
  } catch {
    return err;
  }
}

// Even though we have an [error boundary](https://www.npmjs.com/package/react-error-boundary) we still need to handle
// some errors ourselves, namely errors during async code + event handlers
window.addEventListener('error', (err: ErrorEvent) => {
  errorBoundaryLogger.error(
    {
      err: formatError(err.error),
      strErrorMessage: err.message,
      strCurrentUrl: window.location.href,
      context: err,
    },
    'Uncaught error',
  );
  analytics.trackError(Errors.UNKNOWN_ERROR, err.message);
});

window.addEventListener('unhandledrejection', (err: PromiseRejectionEvent) => {
  errorBoundaryLogger.error(
    { err: err.reason, strCurrentUrl: window.location.href, context: err },
    'Unhandled promise rejection',
  );
  analytics.trackError(Errors.UNKNOWN_ERROR, 'message' in err.reason ? err.reason.message : err.reason);
});

export const ErrorBoundary = ({ children }: PropsWithChildren) => {
  return (
    <ReactErrorBoundary
      onError={(err, info) => {
        errorBoundaryLogger.error(
          { err, strComponentStack: info.componentStack, strCurrentUrl: window.location.href },
          'Error caught by error boundary',
        );
        analytics.trackError(Errors.UNKNOWN_ERROR, err.message);
      }}
      fallback={<ErrorPage />}
    >
      {children}
    </ReactErrorBoundary>
  );
};
