import { GeocodeServiceV1 } from '@garner-health/api-client/geocode-v1';
import createLogger from '~/logging';
import { Position } from '~/util';
import { baseClientOptions, getDefaultHeaders } from './common';

const log = createLogger(__filename);

// exported for testing
export interface LocationResult {
  formattedAddress: string;
  locationType: 'place' | 'fullAddress';
  position: Position;
}

class GeocodeClient {
  private client: GeocodeServiceV1;
  constructor() {
    this.client = new GeocodeServiceV1(baseClientOptions);
  }

  async geocodeLocation(query: string): Promise<LocationResult[]> {
    return this.client
      .get('/geocode', {
        headers: await getDefaultHeaders(),
        query: { query },
      })
      .onStatus(200, r => r.json.items)
      .catch(err => {
        log.error({ err, strQuery: query }, 'Error geocoding location');
        throw Error('Error geocoding location');
      });
  }
}

export const geocodeClient = new GeocodeClient();
