import qs from 'qs';
import { Body, Headers, HttpMethod, NetworkAdapter, normalizeHeaders, Response } from './core';

export type OperationInput = {
  params?: Record<string, unknown>;
  headers?: Headers;
  query?: Record<string, any>;
} & Body;

function resolveUrl(baseUrl: string, path: string): URL {
  const trimmedBaseUrl = baseUrl.endsWith('/') ? baseUrl.slice(0, -1) : baseUrl;
  const trimmedCompleted = path.startsWith('/') ? path.slice(1) : path;
  return new URL(`${trimmedBaseUrl}/${trimmedCompleted}`);
}

function renderUrl(
  baseUrl: string,
  template: string,
  params?: Record<string, any>,
  query?: Record<string, any>,
): string {
  const completed = template.replace(/\{([^}]+)\}/g, (match, tag) => {
    const val = params && params[tag];
    if (val == null) throw new Error(`No parameter provided for URL template tag ${match} in ${template}`);
    return val;
  });

  const url = resolveUrl(baseUrl, completed);
  if (query) {
    return `${url}?${qs.stringify(query, { arrayFormat: 'repeat', skipNulls: true })}`;
  }
  return url.toString();
}

function contentTypeHeaders(body: Body): Headers | undefined {
  if ('body' in body) {
    return { 'Content-Type': body.body.type || 'application/octet-stream' };
  } else if ('json' in body) {
    return { 'Content-Type': 'application/json;charset=utf-8' };
  }

  return undefined;
}

function versionHeaders(version: number | undefined): Headers | undefined {
  if (version != null) {
    return { 'Accept-Version': version.toString() };
  }

  return undefined;
}

function getErrorMessage(response: any): string | undefined {
  const message = response?.json?.message;
  return typeof message === 'string' ? message : undefined;
}

export class UnhandledHttpResponseError extends Error {
  constructor(public readonly response: Response) {
    super(`Unhandled HTTP response with status ${response.status}`);
    this.name = 'UnhandledHttpResponseError';

    const message = getErrorMessage(response);
    if (message) {
      this.message += `: ${message}`;
    }

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (Error.captureStackTrace) {
      // v8 only
      Error.captureStackTrace(this, UnhandledHttpResponseError);
    }
  }
}

export type ResponseMap = Partial<Record<any, Response>>;

export interface FluentExecution<StatusCodeMap extends ResponseMap, Result = never> {
  onStatus<StatusCode extends keyof StatusCodeMap, HandledResult>(
    statusCode: StatusCode,
    handler: (response: Exclude<StatusCodeMap[StatusCode], undefined>) => HandledResult | Promise<HandledResult>,
  ): {} extends Omit<StatusCodeMap, StatusCode>
    ? Promise<Result | HandledResult>
    : FluentExecution<Omit<StatusCodeMap, StatusCode>, Result | HandledResult>;
}

export interface ClientOptions {
  readonly adapter: NetworkAdapter;
  readonly baseUrl: string;
  readonly baseHeaders?: Headers;
}

export class Execution<StatusCodeMap extends ResponseMap = ResponseMap, Result = never>
  implements FluentExecution<StatusCodeMap, Result>, Promise<Result>
{
  readonly [Symbol.toStringTag] = 'Execution';
  readonly then: Promise<Result>['then'];
  readonly catch: Promise<Result>['catch'];
  readonly finally: Promise<Result>['finally'];

  private readonly handlers: Partial<Record<keyof StatusCodeMap, (res: Response) => any>> = {};

  constructor(
    { adapter: fetch, baseUrl, baseHeaders }: ClientOptions,
    method: HttpMethod,
    template: string,
    { params, headers, query, ...body }: OperationInput,
    version?: number,
  ) {
    let invocation;
    try {
      invocation = fetch({
        ...body,
        url: renderUrl(baseUrl, template, params, query),
        method,
        headers: normalizeHeaders(
          Object.entries({
            ...baseHeaders,
            ...contentTypeHeaders(body),
            Accept: 'application/json',
            ...headers,
            ...versionHeaders(version),
          }),
        ),
      }).then(res => this.handleResponse(res));
    } catch (err) {
      invocation = Promise.reject(err);
    }

    this.then = invocation.then.bind(invocation);
    this.catch = invocation.catch.bind(invocation);
    this.finally = invocation.finally.bind(invocation);
  }

  onStatus<StatusCode extends keyof StatusCodeMap, HandledResult>(
    statusCode: StatusCode,
    handler: (res: Exclude<StatusCodeMap[StatusCode], undefined>) => HandledResult | Promise<HandledResult>,
  ): Execution<Omit<StatusCodeMap, StatusCode>, Result | HandledResult> {
    this.handlers[statusCode] = handler as (res: Response) => any;
    return this;
  }

  private async handleResponse(res: Response): Promise<any> {
    const handler = this.handlers[res.status];

    return handler ? handler(res) : Promise.reject(new UnhandledHttpResponseError(res));
  }
}
