import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { Box, Grid, IconButton, Typography } from '@garner-health/components-common';
import { SR } from '@garner-health/lib-ui-content-management';

export const AccountDetailsHeader = ({ onClickBack, isEditing }: { onClickBack: () => void; isEditing: boolean }) => {
  return (
    <Box paddingY="md">
      <Grid>
        <Grid.Item xs={1}>
          <IconButton aria-label="back" onClick={onClickBack}>
            <ArrowBackIcon color="action" />
          </IconButton>
        </Grid.Item>
        <Grid.Item xs={10} textAlign="center">
          <Typography variant="h1">
            <SR package="account" id={isEditing ? 'editProfileHeaderText' : 'accountHeader'} />
          </Typography>
        </Grid.Item>

        <Grid.Item xs={1} />
      </Grid>
    </Box>
  );
};
