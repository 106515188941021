import { useFlags } from './main';
import { useEffect, useState } from 'react';
/**
 * HOC to declaratively manage experiments and feature flags.
 * This is loosely typed because the TypeScript compiler reduces the type `keyof FeatureFlags` to a union of
 * FeatureFlags keys, which results in the type `never`.
 * [See related issue in GitHub](https://github.com/microsoft/TypeScript/issues/49536).
 * @param name Name of the experiment we want to run
 */
export const UntypedExperiment = ({ name, children }) => {
    const flags = useFlags();
    const [value, setValue] = useState(null);
    useEffect(() => {
        const flagValue = flags[name];
        setValue(flagValue);
        /*
         * Don't watch `flags` since we have `streaming: true` on our LaunchDarkly config and we don't
         * want to interrupt a page view with a new flag value
         */
    }, []);
    if (value === null)
        return null;
    return children(value);
};
