import React, { useEffect, useState } from 'react';
import {
  createBrowserRouter,
  Location,
  matchRoutes,
  Navigate,
  Outlet,
  RouteObject,
  useLocation,
} from 'react-router-dom';
import { PrivateRoute, useAuth } from '@garner-health/components-auth';
import { PageLayout } from '@garner-health/components-common';
import { useIdentifyForFeatureFlags } from '@garner-health/lib-ui-feature-flags';
import { Event } from '~/analytics';
import { getTokenClaims } from '~/auth';
import { bowserParser } from '~/browser';
import { AppFooter, AppHeader } from '~/components';
import { useScopeInfo } from '~/contexts';
import logger from '~/logging';
import {
  AccountDetailsPage,
  ErrorPage,
  ForgotPasswordPage,
  LoginPage,
  NotFoundPage,
  ProviderDetailsPage,
  ResetPasswordConfirmationPage,
  ResetPasswordPage,
  SearchPage,
} from '~/pages';
import { analytics } from '~/analytics/segment';
import { SsoAuthPage } from '~/pages/sso-auth-page';
import { useAppBanners } from './use-app-banners';

const log = logger(__filename);

type RouteObjectWithData = RouteObject & {
  containScroll?: boolean;
};

const routeObjects: RouteObjectWithData[] = [
  {
    element: (
      <PrivateRoute>
        <SearchPage />
      </PrivateRoute>
    ),
    path: '/',
    containScroll: true,
  },
  {
    element: (
      <PrivateRoute>
        <SearchPage />
      </PrivateRoute>
    ),

    path: '/search',
    containScroll: true,
  },
  {
    path: '/account',
    element: (
      <PrivateRoute>
        <AccountDetailsPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/providers/:id',
    element: (
      <PrivateRoute>
        <ProviderDetailsPage />
      </PrivateRoute>
    ),
  },
  {
    path: '/sign-in',
    element: <LoginPage />,
  },
  {
    path: '/login',
    element: <Navigate to="/sign-in" />,
  },
  {
    path: '/auth',
    element: <SsoAuthPage />,
  },
  {
    path: '/forgot-password',
    element: <ForgotPasswordPage />,
  },
  {
    path: '/reset-password',
    element: <ResetPasswordPage />,
  },
  {
    path: '/send-reset-password-confirmation',
    element: <ResetPasswordConfirmationPage />,
  },
  {
    path: '*',
    element: <NotFoundPage />,
  },
];

function generatePathPatterns(routes: RouteObject[]): string[] {
  return routes
    .flatMap(route => {
      if (!route.children) return route.path || '/'; // NOSONAR
      return generatePathPatterns(route.children).map(p => `${route.path}/${p}`);
    })
    .filter(Boolean);
}

const pathPatterns = generatePathPatterns(routeObjects);

/** Util to get the pattern of the current path */
function getPathPattern(location: Location) {
  const matchedRoutes = matchRoutes(
    pathPatterns.map(path => ({ path })),
    location,
  );
  if (!matchedRoutes) return null;
  return matchedRoutes.find(m => m.pathname === location.pathname)?.route.path ?? null;
}

const AppWrapper = () => {
  const location = useLocation();
  const auth = useAuth();
  const [containScroll, setContainScroll] = useState(false);
  const isDemo = !!useScopeInfo()?.isDemo;

  const identifyForFeatureFlags = useIdentifyForFeatureFlags();

  useEffect(() => {
    const path = getPathPattern(location);
    analytics.setGlobalProperties({ pageId: path });
    analytics.track(Event.NAVIGATE);

    const newContainScroll = routeObjects.find(m => m.path === path)?.containScroll ?? false;

    if (newContainScroll !== containScroll) {
      setContainScroll(newContainScroll);
    }
  }, [location]);

  useEffect(() => {
    if (auth.state === 'LOGGED_IN') {
      analytics.authSegment().catch(err => log.error({ err }, 'Error initializing segment'));
      getTokenClaims()
        .then(({ clientId, careNavigatorId }) => {
          identifyForFeatureFlags({
            kind: 'datapro',
            key: careNavigatorId.toString(),
            clientId: clientId.toString(),
            appVersion: GARNER_VERSION,
            browser: bowserParser.getBrowserName(),
          });
        })
        .catch(err => log.error({ err }, 'Error getting token claims to identify user for feature flags'));
    }
  }, [auth, auth.state, identifyForFeatureFlags, log]);

  const banners = useAppBanners(isDemo);

  return (
    <PageLayout containScroll={containScroll}>
      <PageLayout.Header divider banners={banners}>
        <AppHeader />
      </PageLayout.Header>
      <PageLayout.Main paddingTop="xs" paddingBottom="none" paddingX="none">
        <Outlet />
      </PageLayout.Main>
      <PageLayout.Footer divider paddingTop="xs" paddingBottom="xs">
        <AppFooter />
      </PageLayout.Footer>
    </PageLayout>
  );
};

export const router = createBrowserRouter([
  {
    element: <AppWrapper />,
    errorElement: <ErrorPage />,
    children: routeObjects,
  },
]);
