import { NavigateFunction } from 'react-router-dom';
import { objectToCamel } from 'ts-case-convert';
import { ProviderServiceV1 } from '@garner-health/api-client/provider-v1';
import { failure, Response, success } from '@garner-health/lib-ts-common';
import createLogger from '~/logging';
import { Position } from '~/util';
import { routes } from '~/router/routes';
import { baseClientOptions, chunkArray, getDefaultHeaders } from './common';
import { Hours, Metric } from './r12n';

const log = createLogger(__filename);

// URL can have max length of 2048 and each id is a 36 char uuid
// https://datapro.getgarner.com/api/provider-networks is 52 characters
// the repeated query param contents `&id=` add 4 characters per ID
// 40 * (36 + 4) + 52 = 1652, so we leave some buffer
const MAX_IDS_TO_INCLUDE = 40;

export interface Network {
  id: string;
  name: string;
}

type AnnotationField =
  | 'phoneNumber'
  | 'street1'
  | 'street2'
  | 'city'
  | 'state'
  | 'zipCode'
  | 'faxNumber'
  | 'acceptsNewPatients'
  | 'inaccessibleSiteOfService';

interface CreateAnnotationParams {
  providerId: string;
  searchParams: ProviderDetailParams;
  annotations: Annotation[];
}

export interface Annotation {
  field: AnnotationField;
  value: string;
  isCorrect: boolean;
  correctedValue: string;
}
export interface ProviderDetailParams {
  specialty: string;
  locationId: string;
  networkId: string;
}
interface ProfessionalNetwork {
  networkId: string;
  isInNetwork: boolean;
  isTopProvider: boolean;
}
interface ProfessionalSpecialtyLocation {
  locationId: string;
  networks: ProfessionalNetwork[];
}

export interface ProfessionalSpecialty {
  overallScore: number;
  locations: ProfessionalSpecialtyLocation[];
}
export interface LocationBase {
  lines: string[];
  city: string;
  state: string;
  zipCode: string;
  position: Position;
  hours?: Hours[];
  phoneNumber?: string;
  faxNumber?: string;
}
export interface ProfessionalLocation extends LocationBase {
  name?: string;
  acceptsNewPatients?: boolean;
}
export interface Professional {
  id: string;
  npi: string;
  firstName: string;
  lastName: string;
  credentials?: string;
  reviewStars?: number;
  sex: 'female' | 'male';
  languages?: string[];
  metrics: Metric[];
  specialties: Map<string, ProfessionalSpecialty>;
  locations: Map<string, ProfessionalLocation>;
}

interface FacilityNetwork {
  networkId: string;
  isInNetwork: boolean;
}

interface FacilitySpecialty {
  networks: FacilityNetwork[];
}

export interface FacilityLocation extends LocationBase {
  locationId: string;
}
export interface Facility {
  id: string;
  npi: string;
  organizationName: string;
  specialties: Map<string, FacilitySpecialty>;
  location: FacilityLocation;
}

export type ProviderFailureReason = 'notFound';

export class ProviderClient {
  // visible for testing
  client: ProviderServiceV1;
  constructor(options = baseClientOptions) {
    this.client = new ProviderServiceV1(options);
  }

  async getNetworks(ids: string[]): Promise<Network[]> {
    const networkIdChunks = chunkArray(ids, MAX_IDS_TO_INCLUDE);
    const response = await Promise.all(
      networkIdChunks.map(async networkIds =>
        this.client
          .get('/provider-networks', {
            headers: await getDefaultHeaders(),
            query: { id: networkIds },
          })
          .onStatus(200, r => r.json.items)
          .catch(err => {
            log.error({ err, networkId: ids }, 'Error getting networks');
            return [];
          }),
      ),
    );

    return response.flat();
  }

  async createAnnotation({ providerId, searchParams, annotations }: CreateAnnotationParams): Promise<void> {
    const { locationId, networkId, specialty } = searchParams;
    await this.client
      .post('/provider-annotations/{provider_id}', {
        headers: await getDefaultHeaders(),
        params: { provider_id: providerId },
        json: {
          locationId,
          networkId,
          specialty,
          annotations,
        },
      })
      .onStatus(200, () => void 0)
      .onStatus(422, r => {
        log.error(
          {
            message: r.json.message,
            requestId: r.json.requestId,
            context: { ...r.json, annotations },
          },
          'Invalid annotation',
        );
      })
      .catch(err => {
        log.error(
          { err, locationId, networkId, strSpecialty: specialty, context: annotations },
          'Error submitting annotation',
        );
      });
  }

  async getProfessional({
    providerId,
    networkId,
  }: {
    providerId: string;
    networkId: string;
  }): Promise<Response<Professional, ProviderFailureReason>> {
    const response = await this.client
      .get('/professionals/{professional_id}', {
        headers: await getDefaultHeaders(),
        params: { professional_id: providerId },
        query: { network_id: [networkId] },
      })
      .onStatus(200, r => {
        const specialtyMap = new Map();
        const locationMap = new Map();
        for (const key in r.json.specialties) {
          specialtyMap.set(key, objectToCamel(r.json.specialties[key]));
        }
        for (const key in r.json.locations) {
          locationMap.set(key, objectToCamel(r.json.locations[key]));
        }
        return {
          ...objectToCamel(r.json),
          metrics: r.json.metrics ?? [],
          specialties: specialtyMap,
          locations: locationMap,
        };
      })
      .onStatus(404, err => {
        log.error({ message: err.json.message, providerId, networkId }, 'Professional not found');
        return null;
      })
      .onStatus(422, err => {
        log.error({ message: err.json.message, providerId, networkId }, 'Bad request when getting professional');
        return null;
      })
      .catch(err => {
        log.error({ err, providerId, networkId }, 'Error getting professional');
        throw err;
      });

    if (!response) return failure('notFound');
    return success(response);
  }

  async getFacility({
    providerId,
    locationId,
    networkId,
  }: {
    providerId: string;
    locationId: string;
    networkId: string;
  }): Promise<Response<Facility, ProviderFailureReason>> {
    const response = await this.client
      .get('/facilities/{facility_id}', {
        headers: await getDefaultHeaders(),
        params: { facility_id: providerId },
        query: { location_id: locationId, network_id: [networkId] },
      })
      .onStatus(200, r => {
        const specialtyMap = new Map();
        for (const key in r.json.specialties) {
          specialtyMap.set(key, objectToCamel(r.json.specialties[key]));
        }
        return {
          ...objectToCamel(r.json),
          npi: r.json.npi!, // TODO: remove this assertion when this ticket is addressed: https://app.asana.com/0/1203374383582495/1205452174479088/f
          specialties: specialtyMap,
        };
      })
      .onStatus(404, err => {
        log.error({ message: err.json.message, providerId, networkId }, 'Facility not found');
        return null;
      })
      .onStatus(422, err => {
        log.error({ message: err.json.message, providerId, networkId }, 'Bad request when getting facility');
        return null;
      })
      .catch(err => {
        log.error({ err, providerId, networkId }, 'Error getting facility');
        throw err;
      });

    if (!response) return failure('notFound');
    return success(response);
  }
}

export const handleFailureReason = (reason: ProviderFailureReason, { navigate }: { navigate: NavigateFunction }) => {
  const failureReasonHandlerMap: Record<ProviderFailureReason, () => void> = {
    notFound: () => navigate(routes.notFound()),
  };
  const failureReasonHandler = failureReasonHandlerMap[reason] as (() => void) | undefined;

  if (failureReasonHandler) {
    failureReasonHandler();
  } else {
    throw new Error('Error loading provider');
  }
};

export const providerClient = new ProviderClient();
