import { useCallback, useEffect, useState } from 'react';
import { useIsMounted } from '~/hooks/use-is-mounted';

type LoadedResponse<TData> = { data: TData; loading: false };
type LoadingResponse = { data: null; loading: true };

type Query = { query: (args?: any) => void };
type LoadedQueryResponse<TData> = LoadedResponse<TData> & Query;
type LoadingQueryResponse = LoadingResponse & Query;
export type UseQueryApiResponse<TData> = LoadedQueryResponse<TData> | LoadingQueryResponse;

//TODO: infer requestFn args
// https://app.asana.com/0/1203374383582495/1205127243912395/f
export function useQueryApi<TData>(
  requestFn: (args?: any) => Promise<TData>,
  lazy = false,
): UseQueryApiResponse<TData> {
  const [loading, setLoading] = useState(!lazy);
  const [data, setData] = useState<TData | null>(null);
  const isMounted = useIsMounted();

  function makeRequest(args?: any) {
    setLoading(true);
    (async () => {
      const response = await requestFn(args);
      if (isMounted()) {
        setData(response);
        setLoading(false);
      }
    })();
  }

  useEffect(() => {
    if (!lazy) makeRequest();
  }, [requestFn, lazy]);

  const query = useCallback(makeRequest, [requestFn]);

  if (loading) {
    return { loading, data, query } as LoadingQueryResponse;
  }

  return { loading, data, query } as LoadedQueryResponse<TData>;
}

export type Mutate = { mutate: () => void };
type LoadedMutateResponse<TData> = LoadedResponse<TData> & Mutate;
type LoadingMutateResponse = LoadingResponse & Mutate;
type UseMutateApiResponse<TData> = LoadedMutateResponse<TData> | LoadingMutateResponse;

export function useMutateApi<TData>(requestFn: () => Promise<TData>): UseMutateApiResponse<TData> {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<TData | null>(null);
  const isMounted = useIsMounted();

  const mutate = useCallback(() => {
    setLoading(true);
    async function makeRequest() {
      const response = await requestFn();
      if (isMounted()) {
        setData(response);
        setLoading(false);
      }
    }
    makeRequest();
  }, [requestFn]);

  if (loading) {
    return { loading, data, mutate } as LoadingMutateResponse;
  }

  return { loading, data, mutate } as LoadedMutateResponse<TData>;
}
