import { useLocation, useNavigate } from 'react-router-dom';

/**
 * Attempt to go "back" on page in the browser history, and use the `fallback` if it is not possible to go back
 * (eg. if the current page is the first one of the session)
 */
export function useGoBack(fallback = '/') {
  const location = useLocation();
  const navigate = useNavigate();

  return () => {
    if (location.key !== 'default') {
      navigate(-1);
    } else {
      navigate(fallback);
    }
  };
}
