// TODO: Test
type JSONSearchParams = Record<string, string | string[] | null | undefined>;
export function toSearchParams(data: JSONSearchParams): URLSearchParams {
  const params = new URLSearchParams();

  for (const [key, value] of Object.entries(data)) {
    if (value == null || value === '') continue;

    if (Array.isArray(value)) {
      value.forEach(v => params.append(key, v));
    } else if (typeof value === 'string') {
      params.set(key, value);
    } else {
      params.set(key, JSON.stringify(value));
    }
  }

  return params;
}

export function fromSearchParams(params: URLSearchParams): JSONSearchParams {
  const jsonParams: JSONSearchParams = {};
  for (const [key, value] of params.entries()) {
    jsonParams[key] = value;
  }
  return jsonParams;
}
