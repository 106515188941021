import { getContent } from '@garner-health/lib-ui-content-management';

export interface Specialty {
  id: string;
  name: string;
  methodology?: string;
}

export interface ProviderMetric {
  id: string;
  title: string;
  description: string;
}

export interface ProviderMetricMetadata {
  id: string;
  qualifier: string;
}

class ExternalContentClient {
  async getSpecialties(): Promise<Map<string, Specialty>> {
    const terms = await getContent<Specialty>({ contentType: 'medicalTerm' });
    return new Map(terms.map(term => [term.id, term]));
  }

  async getMetricDetails(): Promise<Map<string, ProviderMetric>> {
    const metrics = await getContent<ProviderMetric>({ contentType: 'metric' });
    return new Map(metrics.map(metric => [metric.id, metric]));
  }

  async getMetricMetadata(): Promise<Map<string, ProviderMetricMetadata>> {
    const metricMetadata = await getContent<ProviderMetricMetadata>({ contentType: 'metricMetaData' });
    return new Map(metricMetadata.map(metadatum => [metadatum.id, metadatum]));
  }
}

export const externalContentClient = new ExternalContentClient();
