import * as util from 'util';
const maxSerializedBodyLength = 500;
const bodySerializeOptions = {
    colors: false,
    depth: 2,
    maxArrayLength: 5,
    maxStringLength: 50,
    compact: true,
    breakLength: Infinity,
};
function serializeBody(body) {
    if (body == null)
        return undefined;
    const str = util.inspect(body, bodySerializeOptions);
    return str.length < maxSerializedBodyLength ? str : `${str.slice(0, maxSerializedBodyLength)} (truncated)`;
}
function serializeFastifyRequest(req) {
    /* eslint-disable @typescript-eslint/no-unnecessary-condition */
    return {
        method: req.method,
        url: req.url,
        params: req.params,
        // TODO: Only use `req.routeOptions.url` once we've updated our fastify version everywhere
        endpoint: req.routeOptions ? req.routeOptions.url : req.routerPath,
        version: req.headers?.['accept-version'],
        contentType: req.headers?.['content-type'],
        accept: req.headers?.['accept'],
        hostname: req.hostname,
        authorization: req.headers?.authorization,
        query: req.query,
        userAgent: req.headers?.['user-agent'],
        requestBodySize: req.headers?.['content-length'],
        requestBody: serializeBody(req.body),
    };
    /* eslint-enable */
}
function isFastifyReply(req) {
    return req.request != null;
}
export function serializeFastifyMessage(req) {
    if (isFastifyReply(req)) {
        // LoggableResponse extends LoggableRequest with a few more fields
        const serialized = serializeFastifyRequest(req.request);
        serialized.statusCode = req.statusCode;
        serialized.responseBodySize = req.getHeader('content-length');
        return serialized;
    }
    return serializeFastifyRequest(req);
}
