import React from 'react';
import NotSupportedSvg from 'jsx:~/assets/images/not-found-page.svg';
import { Typography } from '@garner-health/components-common';
import { SR, useStringResource } from '@garner-health/lib-ui-content-management';
import { SvgWithSubText } from '~/components/common';

export const NotFoundPage = () => {
  const header = useStringResource('main', 'notFoundPageHeader');

  return (
    <SvgWithSubText
      svg={<NotSupportedSvg role="img" aria-label={header} />}
      header={header}
      subHeader={
        <Typography variant="body1" color="common.muted">
          <SR package="main" id="notFoundPageSubheader" />
        </Typography>
      }
    />
  );
};
