/// <reference lib="dom" />

export type Body = { body: Blob } | { json: unknown } | {};

export type Headers = Partial<Record<string, string>>;

export type HttpMethod = 'DELETE' | 'GET' | 'HEAD' | 'PATCH' | 'POST' | 'PUT' | 'OPTIONS';

export type Request = {
  url: string;
  method: HttpMethod;
  headers?: Headers;
} & Body;

export type Response = {
  url: string;
  status: number;
  headers: Headers;
} & Body;

export type NetworkAdapter = (req: Request) => Promise<Response>;

export interface ServiceError {
  [key: string]: unknown;
  statusCode: number;
  requestId?: number;
  message: string;
  error: string;
  stacktrace?: string;
  data?: unknown;
}

const jsonContentTypeRegexp = /^application\/json(?:;|$)/;
export function isJsonMedia(mediaType: string | undefined | null): boolean {
  return mediaType != null && jsonContentTypeRegexp.test(mediaType);
}

export function normalizeHeaders(entries: Iterable<[key: string, value: string | undefined]>): Headers {
  const headers: Headers = {};

  for (const [key, value] of entries) {
    if (value == null) continue;
    headers[key.toLowerCase()] = value;
  }

  return headers;
}
