import { createHash } from 'crypto';
import { pino } from 'pino';
import * as serializers from './serializers/index.js';
import { enrichLogEvent } from './util.js';
const DEFAULT_BROWSER_TRANSMIT_INTERVAL = 1_000;
function censorAuthorizationHeader(token) {
    const start = token.indexOf('.') + 1;
    const end = token.indexOf('.', start);
    if (end < 0) {
        const hashed = createHash('sha256').update(token).digest('base64');
        return `redacted/sha256+base64:${hashed}`;
    }
    else {
        const payload = token.slice(start, end);
        return Buffer.from(payload, 'base64').toString('utf-8');
    }
}
/** Create a new root logger (not bound to a specific module) */
export function createLogger({ logLevel, logDestination }) {
    const commonOptions = {
        serializers,
        redact: {
            paths: ['*.authorization', '*.headers.authorization'],
            censor(value) {
                return censorAuthorizationHeader(value);
            },
        },
        formatters: {
            log(obj) {
                if (obj.res && !obj.req) {
                    // This object won't have more than ~50 keys, so spread operator is fastest https://jsbench.me/2jl3hidadd/1
                    const clone = { ...obj, req: obj.res };
                    delete clone.res;
                    return clone;
                }
                return obj;
            },
        },
    };
    const logs = [];
    if (isBrowserDestinationConfig(logDestination)) {
        const { path, flushInterval = DEFAULT_BROWSER_TRANSMIT_INTERVAL, lazyBindings = null } = logDestination;
        return pino({
            ...commonOptions,
            browser: {
                transmit: {
                    level: logLevel,
                    send: (_level, logEvent) => {
                        logs.push(logEvent);
                        setTimeout(async () => {
                            if (!logs.length)
                                return;
                            const logsToSend = logs.splice(0);
                            const data = await lazyBindings?.();
                            const body = data != null ? logsToSend.map(log => enrichLogEvent(log, data)) : logsToSend;
                            fetch(path, {
                                headers: { 'Content-Type': 'application/json' },
                                method: 'POST',
                                body: JSON.stringify(body),
                            }).catch(() => void 0);
                        }, flushInterval);
                    },
                },
                asObject: true,
            },
        });
    }
    return pino({
        ...commonOptions,
        level: logLevel,
        base: undefined,
    }, pino.destination(logDestination));
}
function isBrowserDestinationConfig(logDestination) {
    return typeof logDestination === 'object' && 'path' in logDestination;
}
/** Create a logger bound to a specific module name */
export default function createModuleLogger(module, ctx, bindings) {
    return ctx.log.child({ ...bindings, module });
}
