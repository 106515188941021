import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  Form,
  FormattedAddress,
  RadioGroupInput,
  Spinner,
  Stack,
  TextInput,
  ThreeColumnLayout,
  Typography,
} from '@garner-health/components-common';
import { SR, useStringResource } from '@garner-health/lib-ui-content-management';
import { analytics, Event } from '~/analytics';
import {
  Annotation,
  Facility,
  FacilityLocation,
  Professional,
  ProfessionalLocation,
  providerClient,
  ProviderDetailParams,
} from '~/clients';
import { useScopeInfo } from '~/contexts';
import logger from '~/logging';
import { useProviderDetails } from '~/contexts/provider-detail-context';

type SuggestEditDialogProps = {
  isOpen: boolean;
  onClose: () => void;
};

interface FormData {
  inaccessibleSiteOfService: boolean;
  incorrectPhone: boolean;
  incorrectFax: boolean;
  suggestFax: boolean;
  acceptsNewPatients: string;
  street1?: string;
  street2?: string;
  city?: string;
  state?: string;
  zipCode?: string;
  phone?: string;
  fax?: string;
}

const log = logger(__filename);

interface FormFieldToAnnotationFunctionParams {
  correctedValue: string;
  location: ProfessionalLocation | FacilityLocation;
  data: FormData;
}

interface DialogFooterProps {
  onClose: () => void;
  isAnnotationLoading: boolean;
  isFormEmpty: boolean;
}
interface SuggestEditCommon {
  location: ProfessionalLocation;
  isOpen: boolean;
  onClose: () => void;
  searchParams: ProviderDetailParams;
}
interface ProfessionalSuggestEdit extends SuggestEditCommon {
  professional: Professional;
  facility?: never;
}
interface FacilitySuggestEdit extends SuggestEditCommon {
  professional?: never;
  facility: Facility;
}

type SuggestEditDialogBodyProps = ProfessionalSuggestEdit | FacilitySuggestEdit;
type FormFieldToAnnotationFn = (params: FormFieldToAnnotationFunctionParams) => Annotation;

const formFieldToAnnotation: Record<keyof FormData, FormFieldToAnnotationFn | undefined> = {
  inaccessibleSiteOfService: () => {
    return {
      field: 'inaccessibleSiteOfService',
      value: '',
      isCorrect: false,
      correctedValue: '',
    };
  },
  incorrectPhone: ({ location, data }) => {
    return {
      field: 'phoneNumber',
      value: location.phoneNumber ?? '',
      isCorrect: false,
      correctedValue: data.phone ?? '',
    };
  },
  incorrectFax: ({ location, data }) => {
    return {
      field: 'faxNumber',
      value: location.faxNumber ?? '',
      isCorrect: false,
      correctedValue: data.fax ?? '',
    };
  },
  acceptsNewPatients: ({ correctedValue }) => {
    return {
      field: 'acceptsNewPatients',
      value: '',
      isCorrect: false,
      correctedValue,
    };
  },
  street1: ({ correctedValue, location }) => {
    return {
      field: 'street1',
      value: location.lines[0] || '',
      isCorrect: false,
      correctedValue,
    };
  },
  street2: ({ correctedValue, location }) => {
    return {
      field: 'street2',
      value: location.lines[1] || '',
      isCorrect: false,
      correctedValue,
    };
  },
  city: ({ correctedValue, location }) => {
    return {
      field: 'city',
      value: location.city || '',
      isCorrect: false,
      correctedValue,
    };
  },
  state: ({ correctedValue, location }) => {
    return {
      field: 'state',
      value: location.state || '',
      isCorrect: false,
      correctedValue,
    };
  },
  zipCode: ({ correctedValue, location }) => {
    return {
      field: 'zipCode',
      value: location.zipCode || '',
      isCorrect: false,
      correctedValue,
    };
  },
  phone: undefined,
  fax: undefined,
  suggestFax: undefined,
};

const AddressInput = () => {
  return (
    <Box paddingTop="xs">
      <Stack spacing="xs">
        <Typography variant="body1" color="common.muted">
          <SR package="suggestEditDialog" id="optionalAddress" />
        </Typography>
        <TextInput name="street1" label={useStringResource('suggestEditDialog', 'addressPlaceholder')} fullWidth />
        <TextInput name="street2" label="" fullWidth />

        <ThreeColumnLayout>
          <ThreeColumnLayout.Column1>
            <TextInput name="city" label={useStringResource('suggestEditDialog', 'cityPlaceholder')} />
          </ThreeColumnLayout.Column1>

          <ThreeColumnLayout.Column2>
            <TextInput name="state" label={useStringResource('suggestEditDialog', 'statePlaceholder')} />
          </ThreeColumnLayout.Column2>

          <ThreeColumnLayout.Column3>
            <TextInput name="zipCode" label={useStringResource('suggestEditDialog', 'zipCodePlaceholder')} />
          </ThreeColumnLayout.Column3>
        </ThreeColumnLayout>
      </Stack>
    </Box>
  );
};

const PhoneInput = () => {
  return (
    <Box paddingTop="xs">
      <Stack spacing="xs">
        <Typography variant="body1" color="common.muted">
          <SR package="suggestEditDialog" id="optionalPhone" />
        </Typography>
        <TextInput name="phone" label={useStringResource('suggestEditDialog', 'phoneNumberPlaceholder')} fullWidth />
      </Stack>
    </Box>
  );
};

const FaxInput = ({ optional }: { optional?: boolean }) => (
  <Box paddingTop="xs">
    <Stack spacing="xs">
      {optional && (
        <Typography variant="body1" color="common.muted">
          <SR package="suggestEditDialog" id="optionalFax" />
        </Typography>
      )}
      <TextInput name="fax" label={useStringResource('suggestEditDialog', 'faxNumberPlaceholder')} fullWidth />
    </Stack>
  </Box>
);

const DialogFooter = ({ onClose, isAnnotationLoading, isFormEmpty }: DialogFooterProps) => {
  const isDemo = !!useScopeInfo()?.isDemo;

  return (
    <>
      <Button onClick={() => onClose()}>
        <SR package="suggestEditDialog" id="cancelButtonText" />
      </Button>

      <Button
        type="submit"
        variant="primary"
        disabled={isAnnotationLoading || isDemo || isFormEmpty}
        form="suggest-edit-form"
      >
        {isAnnotationLoading ? (
          <Spinner variant="dark" />
        ) : (
          <SR package="suggestEditDialog" id={isDemo ? 'submitButtonDemoText' : 'submitButtonText'} />
        )}
      </Button>
    </>
  );
};

const SuggestEditDialogBody = ({
  professional,
  facility,
  location,
  isOpen,
  searchParams,
  onClose,
}: SuggestEditDialogBodyProps) => {
  const form = useForm<FormData>();

  const [isAnnotationLoading, setIsAnnotationLoading] = useState(false);
  const [inaccessibleSiteOfService, incorrectPhone, incorrectFax, acceptsNewPatients] = form.watch([
    'inaccessibleSiteOfService',
    'incorrectPhone',
    'incorrectFax',
    'acceptsNewPatients',
  ]);

  function formSubmitHandler(data: FormData) {
    setIsAnnotationLoading(true);

    // Build annotations
    const annotations: Annotation[] = [];
    const fields = [];
    for (const [key, correctedValue] of Object.entries(data)) {
      // Handling false and blank newPatients
      if (!correctedValue) continue;
      fields.push(key);
      const fn = formFieldToAnnotation[key as keyof FormData];
      const annotation = fn?.({ correctedValue, location, data });
      if (annotation) annotations.push(annotation);
    }

    analytics.track(Event.SUGGEST_EDIT, { fields });
    const providerId = professional ? professional.id : facility.id;

    providerClient
      .createAnnotation({
        providerId,
        searchParams,
        annotations,
      })
      .catch(err => {
        log.error({ err }, 'Unexpected error creating annotation');
      })
      .finally(() => {
        localOnClose();
      });
  }

  function localOnClose() {
    setIsAnnotationLoading(false);
    form.reset();
    onClose();
  }

  const faxNumber = location.faxNumber;
  const isFormEmpty = !inaccessibleSiteOfService && !incorrectPhone && !incorrectFax && !acceptsNewPatients;
  return (
    <Dialog
      open={isOpen}
      title={useStringResource('suggestEditDialog', 'suggestAnEdit')}
      body={
        <Form form={form} onSubmit={formSubmitHandler} id="suggest-edit-form">
          <Typography variant="body1">
            <SR package="suggestEditDialog" id="disclaimer" />
          </Typography>
          <Box paddingY="xs">
            <Checkbox
              name="inaccessibleSiteOfService"
              label={
                <Typography variant="button">
                  <SR package="suggestEditDialog" id="incorrectAddress" />
                </Typography>
              }
            />
            <Box paddingX="lg">
              <Stack>
                <FormattedAddress
                  address={{
                    lines: location.lines,
                    city: location.city,
                    state: location.state,
                    zipCode: location.zipCode,
                  }}
                />
              </Stack>
              {inaccessibleSiteOfService && <AddressInput />}
            </Box>
          </Box>
          <Box paddingY="xs">
            <Checkbox
              name="incorrectPhone"
              label={
                <Typography variant="button">
                  <SR package="suggestEditDialog" id="incorrectPhoneNumber" />
                </Typography>
              }
            />
            <Box paddingX="lg">
              <Typography variant="body1">{location.phoneNumber}</Typography>
              {incorrectPhone && <PhoneInput />}
            </Box>
          </Box>

          <Box paddingY="xs">
            <Checkbox
              name="incorrectFax"
              label={
                <Typography variant="button">
                  <SR package="suggestEditDialog" id={faxNumber ? 'incorrectFaxNumber' : 'suggestFaxNumber'} />
                </Typography>
              }
            />
            <Box paddingX="lg">
              <Typography variant="body1">{faxNumber}</Typography>
              {incorrectFax && <FaxInput optional={!!faxNumber} />}
            </Box>
          </Box>

          <Box paddingY="xs">
            <Stack spacing="xs">
              <Typography variant="button">
                <SR package="suggestEditDialog" id="newPatients" />
              </Typography>
              <RadioGroupInput
                name="acceptsNewPatients"
                label=""
                options={[
                  { label: useStringResource('suggestEditDialog', 'yesOption'), value: 'yes' },
                  { label: useStringResource('suggestEditDialog', 'noOption'), value: 'no' },
                  { label: useStringResource('suggestEditDialog', 'notSureOption'), value: 'not sure' },
                ]}
              />
            </Stack>
          </Box>
        </Form>
      }
      footer={
        <DialogFooter onClose={localOnClose} isAnnotationLoading={isAnnotationLoading} isFormEmpty={isFormEmpty} />
      }
    />
  );
};

export const SuggestEditDialog = ({ isOpen, onClose }: SuggestEditDialogProps) => {
  const details = useProviderDetails();
  if (!details) return;
  const { professional, facility, locationId, specialty, networkId } = details;
  const searchParams = { locationId, specialty, networkId };
  const location = professional ? professional.locations.get(locationId) : facility.location;
  if (!location) {
    log.error(
      { locationId, professionalId: professional?.id, facilityId: facility?.id },
      'location not found in provider',
    );
    return;
  }

  const props = { isOpen, onClose, location, searchParams };

  if (professional) {
    return <SuggestEditDialogBody {...props} professional={professional} />;
  }

  return <SuggestEditDialogBody {...props} facility={facility} />;
};
