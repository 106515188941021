import { useCallback, useEffect } from 'react';
import {
  GetProvidersParams,
  ProviderResultItem,
  ProvidersResponseSetType,
  r12nClient,
  UnsupportedCareGoalType,
} from '~/clients';
import { useQueryApi } from '~/hooks';
import createLogger from '~/logging';
import { SearchFormData } from '~/components/provider-search';

const log = createLogger(__filename);

const LATEST_SEARCH_CACHE_KEY = 'garnerLatestProvidersQuery';

export type ResponseSetType = ProvidersResponseSetType | UnsupportedCareGoalType;

interface SearchResults {
  providers: ProviderResultItem[];
  responseSetType?: ResponseSetType;
}

export type NullableSearchResults = SearchResults | null;

function getParams(formData: SearchFormData): GetProvidersParams | null {
  if (formData.careGoal && !('specialty' in formData.careGoal)) {
    log.error({ context: formData }, 'specialty was unexpectedly empty');
    return null;
  }

  return {
    specialty: formData.careGoal?.specialty,
    name: formData.providerName ?? undefined,
    networkId: formData.network.value,
    location: formData.location.position,
    language: formData.language ?? undefined,
    gender: formData.gender !== '' && formData.gender !== null ? formData.gender : undefined,
  };
}

function getQueryKey(formData: SearchFormData) {
  const params = getParams(formData);
  return JSON.stringify({
    ...params,
    // Geocoding a zip code directly can result in a _slightly_ different lat + lng than geocoding a fullAddress
    // that represents the same location, so key off the location
    location: formData.location.label,
    // Include careGoalId so that if they tried to search for another care goal with the same specialty we still search
    careGoalId: formData.careGoal?.value,
  });
}

/**
 * Remove overallScore from non top providers.
 * Sort providers array so that all top providers are at the top of the list and the ranking algorithm is otherwise maintained.
 * Provider name searches are sorted in the r12n service so we do not want to sort them by isTopProvider.
 */
export function formatProviders(providers: ProviderResultItem[], sortTopProvidersFirst = true) {
  const formattedProviders = providers.map(({ overallScore, ...provider }) =>
    provider.isTopProvider ? { overallScore, ...provider } : provider,
  );

  if (sortTopProvidersFirst) {
    return formattedProviders.sort((a, b) => Number(b.isTopProvider) - Number(a.isTopProvider));
  }

  return formattedProviders;
}

function useResultsCache() {
  function setCacheValue(formData: SearchFormData, results: SearchResults) {
    const queryKey = getQueryKey(formData);
    sessionStorage.setItem(
      LATEST_SEARCH_CACHE_KEY,
      JSON.stringify({
        queryKey,
        data: results,
      }),
    );
  }

  function getCacheValue(formData: SearchFormData): NullableSearchResults {
    const latestSearch = sessionStorage.getItem(LATEST_SEARCH_CACHE_KEY);
    if (!latestSearch) return null;

    const queryKey = getQueryKey(formData);
    const { queryKey: oldQueryKey, data } = JSON.parse(latestSearch);
    if (queryKey !== oldQueryKey) return null;

    return data;
  }

  return { getCacheValue, setCacheValue };
}

export function useSearchFormResults({
  formData,
  showAdditionalProviders,
}: {
  formData: SearchFormData | null;
  showAdditionalProviders: boolean;
}) {
  const { getCacheValue, setCacheValue } = useResultsCache();

  const search = useCallback(async () => {
    if (!formData) return { providers: [] };

    if (formData.careGoal?.type === 'not_supported') {
      return { providers: [], responseSetType: 'not_supported' as ResponseSetType };
    }

    const cachedValue = getCacheValue(formData);
    if (cachedValue) {
      log.debug({ context: formData }, 'Using providers from session storage');
      return cachedValue;
    }

    const params = getParams(formData);
    if (!params) return { providers: [] };

    const additionalProvidersParams: Partial<GetProvidersParams> | undefined = showAdditionalProviders
      ? { includeLowQualityProviders: true, limit: 30, providerSearchSet: 'in_network' }
      : undefined;

    const { providers: unsortedProviders, responseSetType } = await r12nClient.getProviders({
      ...params,
      ...additionalProvidersParams,
    });

    const providers = formatProviders(unsortedProviders, !params.name);

    setCacheValue(formData, { providers, responseSetType });

    return { providers, responseSetType };
  }, [formData]);

  const { data, loading, query } = useQueryApi<NullableSearchResults>(search, true);

  useEffect(query, [formData]);

  return { data, loading };
}
