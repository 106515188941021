import React from 'react';
import { interpolate, interpolateString } from './interpolation';
import { getConfig } from './content';
import { keyForNewline } from './keygen';
import { useContent } from './content-provider';
function assertIsString(id, content) {
    const typeName = typeof content;
    if (typeName !== 'string')
        getConfig().log.error({ contentId: id, strType: typeName }, 'Content is of invalid type, expected a string.');
}
export function useStringResource(packageId, id, replacer) {
    const contentStore = useContent();
    const group = contentStore[packageId];
    if (!group) {
        getConfig().log.error({ packageId, contentId: id }, 'Package is not registered in content store');
        return '';
    }
    let content = group[id];
    assertIsString(id, content);
    if (replacer) {
        content = interpolateString(content, (value, tag) => replacer[tag](value, tag));
    }
    assertIsString(id, content);
    return content;
}
export function StringResource(props) {
    const contentBundle = useContent()[props.package];
    const content = contentBundle === null || contentBundle === void 0 ? void 0 : contentBundle[props.id];
    assertIsString(props.id, content);
    let node;
    if (typeof props.interpolate === 'function') {
        node = interpolate(content, props.interpolate);
    }
    else if (props.interpolate) {
        const taggedReplacer = props.interpolate;
        node = interpolate(content, (value, tag) => taggedReplacer[tag](value, tag));
    }
    else {
        node = content;
    }
    return React.createElement(React.Fragment, null, React.Children.map(node, n => (typeof n === 'string' ? newlineToBreak(n) : n)));
}
export const SR = StringResource;
function newlineToBreak(content) {
    const lines = content.split('\n');
    if (lines.length < 2)
        return lines;
    const nodes = new Array(2 * lines.length - 1);
    for (let i = 0, j = 0; i < lines.length; i++) {
        nodes[j++] = lines[i];
        if (j < nodes.length)
            nodes[j++] = React.createElement("br", { key: keyForNewline(i) });
    }
    return nodes;
}
