import React from 'react';
import { ExternalLink, Stack, TwoColumnLayout, Typography } from '@garner-health/components-common';
import { SR, useStringResource } from '@garner-health/lib-ui-content-management';
import { analytics, Event } from '~/analytics';

export const AppFooter = () => (
  <TwoColumnLayout alignItems="center">
    <TwoColumnLayout.Column1>
      <Typography variant="caption">
        <SR package="main" id="copyright" />
        {/* eslint-disable react/jsx-no-literals */} • v{GARNER_VERSION}
      </Typography>
    </TwoColumnLayout.Column1>
    <TwoColumnLayout.Column2>
      <Typography variant="caption" component="div">
        <Stack direction="row" justifyContent="flex-end">
          <ExternalLink
            href={useStringResource('main', 'supportEmailLink')}
            color="common.black"
            onClick={() => analytics.track(Event.GENERAL_SUPPORT)}
          >
            <SR package="main" id="footerHelpText" />
          </ExternalLink>
          <ExternalLink
            href={useStringResource('main', 'termsOfServiceUrl')}
            color="common.black"
            onClick={() => analytics.track(Event.TERMS_OF_SERVICE)}
          >
            <SR package="main" id="termsOfService" />
          </ExternalLink>
          <ExternalLink
            href={useStringResource('main', 'privacyStatementUrl')}
            color="common.black"
            onClick={() => analytics.track(Event.PRIVACY_STATEMENT)}
          >
            <SR package="main" id="privacyStatement" />
          </ExternalLink>
        </Stack>
      </Typography>
    </TwoColumnLayout.Column2>
  </TwoColumnLayout>
);
