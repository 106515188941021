import React from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useAuth } from '@garner-health/components-auth';
import { Box, FixedWidthLayout, Grid, IconButton, Stack, Typography } from '@garner-health/components-common';
import { SR, useStringResource } from '@garner-health/lib-ui-content-management';
import { routes } from '~/router/routes';

export const ResetPasswordConfirmationPage = () => {
  const [searchParams] = useSearchParams();
  const email = searchParams.get('email');
  const auth = useAuth();
  const navigate = useNavigate();
  const backButtonAriaLabel = useStringResource('sendResetPasswordConfirmationPage', 'backButtonAriaLabel');

  return (
    <FixedWidthLayout maxWidth="sm">
      <Stack>
        <Box paddingY="md">
          <Grid>
            <Grid.Item xs={1}>
              <IconButton
                aria-label={backButtonAriaLabel}
                onClick={() => navigate(auth.state === 'LOGGED_IN' ? routes.account() : routes.login())}
              >
                <ArrowBackIcon />
              </IconButton>
            </Grid.Item>
            <Grid.Item xs={10} textAlign="center">
              <Typography variant="h1">
                <SR package="sendResetPasswordConfirmationPage" id="headingText" />
              </Typography>
            </Grid.Item>

            <Grid.Item xs={1} />
          </Grid>
        </Box>

        <Typography variant="body1">
          <SR
            package="sendResetPasswordConfirmationPage"
            id="resetPasswordConfirmationText"
            interpolate={() => email}
          />
        </Typography>
      </Stack>
    </FixedWidthLayout>
  );
};
