import { ClientOptions, Execution, FluentExecution, OperationInput } from '@garner-health/api-client-core';
import type { Headers } from '@garner-health/api-client-core';
export type GetProfessionalRequest = {
  params: {
    professional_id: string;
  };
  headers?: Headers;
  query?: {
    location_id?: string;
    network_id?: string[];
    fieldset?: 'ADMIN';
  };
};
export type GetProfessionalResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      id: string;
      npi: string;
      first_name: string;
      last_name: string;
      credentials?: string;
      review_stars?: number;
      sex: 'female' | 'male';
      languages?: string[];
      metrics?: {
        id: string;
        name: string;
        value: string;
      }[];
      specialties: {
        [k: string]: {
          is_recommendable_specialty?: boolean;
          overall_score?: number;
          locations: ({
            cost_pmpm?: number;
            cost_score?: number;
            is_excluded?: boolean;
            exclusion_reasons?: string[];
          } & {
            location_id: string;
            networks: ({
              network_id: string;
              is_in_network: boolean;
            } & {
              is_top_provider: boolean;
            })[];
            review_score?: number;
          })[];
        };
      };
      locations: {
        [k: string]: {
          lines: string[];
          city: string;
          state: string;
          zip_code: string;
          position: {
            lat: number;
            lng: number;
          };
          hours?: {
            day: 1 | 2 | 3 | 4 | 5 | 6 | 7;
            open: number;
            close: number;
          }[];
          phone_number?: string;
          fax_number?: string;
        } & {
          name?: string;
          accepts_new_patients: boolean;
          availability_within_weeks?: number;
          internal_metadata?: {
            address_verified_date?: string;
            phone_verified_date?: string;
          } & {
            address_confidence?: number;
            accepts_new_patients_verified_date?: string;
            accepts_new_patients_confidence?: number;
            availability_within_weeks_verified_date?: string;
          };
        };
      };
    };
  };
  404: {
    status: 404;
    url: string;
    headers: Headers;
    json: {
      requestId?: string;
      message: string;
      error?: string;
      data?: {
        [k: string]: unknown;
      };
    };
  };
  422: {
    status: 422;
    url: string;
    headers: Headers;
    json: {
      requestId?: string;
      message: string;
      error?: string;
      data?: {
        [k: string]: unknown;
      };
    };
  };
};
export type GetFacilityRequest = {
  params: {
    facility_id: string;
  };
  headers?: Headers;
  query: {
    location_id: string;
    network_id?: string[];
    fieldset?: 'ADMIN';
  };
};
export type GetFacilityResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      id: string;
      npi?: string;
      organization_name: string;
      specialties: {
        [k: string]: ({
          cost_pmpm?: number;
          cost_score?: number;
          is_excluded?: boolean;
          exclusion_reasons?: string[];
        } & {
          networks: {
            network_id: string;
            is_in_network: boolean;
          }[];
          [k: string]: unknown;
        }) & {
          is_recommendable_specialty?: boolean;
          [k: string]: unknown;
        };
      };
      location: {
        lines: string[];
        city: string;
        state: string;
        zip_code: string;
        position: {
          lat: number;
          lng: number;
        };
        hours?: {
          day: 1 | 2 | 3 | 4 | 5 | 6 | 7;
          open: number;
          close: number;
        }[];
        phone_number?: string;
        fax_number?: string;
      } & {
        location_id: string;
        internal_metadata?: {
          address_verified_date?: string;
          phone_verified_date?: string;
        };
        [k: string]: unknown;
      };
    };
  };
  404: {
    status: 404;
    url: string;
    headers: Headers;
    json: {
      requestId?: string;
      message: string;
      error?: string;
      data?: {
        [k: string]: unknown;
      };
    };
  };
  422: {
    status: 422;
    url: string;
    headers: Headers;
    json: {
      requestId?: string;
      message: string;
      error?: string;
      data?: {
        [k: string]: unknown;
      };
    };
  };
};
export type GetImagesPendingApprovalRequest = {
  headers?: Headers;
  query: {
    status: 'pending-approval';
  };
};
export type GetImagesPendingApprovalResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      images: {
        providerId: string;
        images: {
          url: string;
          name: string;
        }[];
      }[];
    };
  };
};
export type UpdateImagesPendingApprovalRequest = {
  headers?: Headers;
  json: {
    images: {
      approved: boolean;
      imageNames: string[];
    }[];
  };
};
export type UpdateImagesPendingApprovalResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      successful: {
        imageName: string;
        approved: boolean;
      }[];
    };
  };
  207: {
    status: 207;
    url: string;
    headers: Headers;
    json: {
      successful: {
        imageName: string;
        approved: boolean;
      }[];
      failed: {
        imageName: string;
        approved: boolean;
      }[];
    };
  };
};
export type RetrieveProviderAnnotationsRequest = {
  headers: {
    [name: string]: string;
    Accept: string;
  };
  query: {
    from: string;
    to: string;
  };
};
export type RetrieveProviderAnnotationsResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    body: Blob;
  };
  422: {
    status: 422;
    url: string;
    headers: Headers;
    body: Blob;
  };
};
export type CreateProviderAnnotationsRequest = {
  params: {
    provider_id: string;
  };
  headers?: Headers;
  json: {
    locationId?: string;
    networkId?: string;
    specialty?: string;
    procedure?: string;
    annotations: (
      | {
          field:
            | 'specialty'
            | 'phoneNumber'
            | 'firstName'
            | 'lastName'
            | 'gender'
            | 'credentials'
            | 'activelyTreatingPatients'
            | 'hasAccessibleSitesOfService'
            | 'practicesConciergeMedicine'
            | 'knownDisciplinaryAction'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'state'
            | 'zipCode'
            | 'availabilityWithinWeeks'
            | 'faxNumber'
            | 'acceptsNewPatients'
            | 'inaccessibleSiteOfService'
            | 'procedure'
            | 'procedureCost'
            | 'networkId'
            | 'other';
          value: string;
          isCorrect: boolean;
          correctedValue?: string;
        }
      | {
          field:
            | 'specialty'
            | 'phoneNumber'
            | 'firstName'
            | 'lastName'
            | 'gender'
            | 'credentials'
            | 'activelyTreatingPatients'
            | 'hasAccessibleSitesOfService'
            | 'practicesConciergeMedicine'
            | 'knownDisciplinaryAction'
            | 'locationName'
            | 'street1'
            | 'street2'
            | 'city'
            | 'state'
            | 'zipCode'
            | 'availabilityWithinWeeks'
            | 'faxNumber'
            | 'acceptsNewPatients'
            | 'inaccessibleSiteOfService'
            | 'procedure'
            | 'procedureCost'
            | 'networkId'
            | 'other';
          value: boolean;
          isCorrect: boolean;
          correctedValue?: boolean;
        }
    )[];
  };
};
export type CreateProviderAnnotationsResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      count: number;
    };
  };
  422: {
    status: 422;
    url: string;
    headers: Headers;
    json: {
      requestId?: string;
      message: string;
      error?: string;
      data?: {
        [k: string]: unknown;
      };
    };
  };
};
export type GetProviderNetworksRequest = {
  headers?: Headers;
  query?: {
    name?: string;
    id?: string | string[];
    offset?: number;
    limit?: number;
  };
};
export type GetProviderNetworksResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      total: number;
      offset: number;
      limit: number;
    } & {
      items: {
        id: string;
        name: string;
        createdAt: string;
        updatedAt: string;
      }[];
      [k: string]: unknown;
    };
  };
};
export type CreateProviderNetworkRequest = {
  headers?: Headers;
  json: {
    id: string;
    name: string;
  };
};
export type CreateProviderNetworkResponses = {
  201: {
    status: 201;
    url: string;
    headers: Headers;
    json: {
      id: string;
      [k: string]: unknown;
    };
  };
  409: {
    status: 409;
    url: string;
    headers: Headers;
    json: {
      requestId?: string;
      message: string;
      error?: string;
      data?: {
        [k: string]: unknown;
      };
    };
  };
};
export type GetUrl =
  | '/professionals/{professional_id}'
  | '/facilities/{facility_id}'
  | '/provider-images'
  | '/provider-annotations'
  | '/provider-networks';
export type PatchUrl = '/provider-images';
export type PostUrl = '/provider-annotations/{provider_id}' | '/provider-networks';
type RequestsByMethodAndPath = {
  'GET /professionals/{professional_id}': GetProfessionalRequest;
  'GET /facilities/{facility_id}': GetFacilityRequest;
  'GET /provider-images': GetImagesPendingApprovalRequest;
  'PATCH /provider-images': UpdateImagesPendingApprovalRequest;
  'GET /provider-annotations': RetrieveProviderAnnotationsRequest;
  'POST /provider-annotations/{provider_id}': CreateProviderAnnotationsRequest;
  'GET /provider-networks': GetProviderNetworksRequest;
  'POST /provider-networks': CreateProviderNetworkRequest;
};
type ResponsesByMethodAndPath = {
  'GET /professionals/{professional_id}': GetProfessionalResponses;
  'GET /facilities/{facility_id}': GetFacilityResponses;
  'GET /provider-images': GetImagesPendingApprovalResponses;
  'PATCH /provider-images': UpdateImagesPendingApprovalResponses;
  'GET /provider-annotations': RetrieveProviderAnnotationsResponses;
  'POST /provider-annotations/{provider_id}': CreateProviderAnnotationsResponses;
  'GET /provider-networks': GetProviderNetworksResponses;
  'POST /provider-networks': CreateProviderNetworkResponses;
};
export class ProviderServiceV1 {
  constructor(private readonly options: ClientOptions) {}
  get<Url extends GetUrl>(
    url: Url,
    input: RequestsByMethodAndPath[`GET ${Url}`],
  ): FluentExecution<ResponsesByMethodAndPath[`GET ${Url}`]>;
  get(url: string, input: OperationInput = {}): FluentExecution<Record<number, any>> {
    return new Execution(this.options, 'GET', url, input, 1);
  }
  patch<Url extends PatchUrl>(
    url: Url,
    input: RequestsByMethodAndPath[`PATCH ${Url}`],
  ): FluentExecution<ResponsesByMethodAndPath[`PATCH ${Url}`]>;
  patch(url: string, input: OperationInput = {}): FluentExecution<Record<number, any>> {
    return new Execution(this.options, 'PATCH', url, input, 1);
  }
  post<Url extends PostUrl>(
    url: Url,
    input: RequestsByMethodAndPath[`POST ${Url}`],
  ): FluentExecution<ResponsesByMethodAndPath[`POST ${Url}`]>;
  post(url: string, input: OperationInput = {}): FluentExecution<Record<number, any>> {
    return new Execution(this.options, 'POST', url, input, 1);
  }
}
