import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '@garner-health/components-auth';
import { Button, Divider, FixedWidthLayout, Stack, Typography } from '@garner-health/components-common';
import { ResourcePackages, SR } from '@garner-health/lib-ui-content-management';
import { analytics, Event } from '~/analytics';
import { useCareNavigator } from '~/contexts';
import { useGoBack } from '~/hooks';
import { AccountDetailsHeader, EditAccountDetailsBody } from '~/components/account-details';
import { routes } from '~/router/routes';

type AccountUseStringResourceId = keyof ResourcePackages['account'];

interface AccountDetailsProps {
  name: AccountUseStringResourceId;
  value?: string;
  fallback?: AccountUseStringResourceId;
}

const AccountDetailsProperty = (props: AccountDetailsProps) => (
  <Stack direction="row" justifyContent="space-between">
    <Typography variant="body2">
      <SR package="account" id={props.name} />
    </Typography>
    {props.value ? (
      <Typography variant="body1">{props.value}</Typography>
    ) : (
      <Typography variant="body1" color="common.muted">
        <SR package="account" id={props.fallback!} />
      </Typography>
    )}
  </Stack>
);

const AccountDetailsBody = ({ fields }: { fields: AccountDetailsProps[] }) => (
  <Stack>
    {fields.map(field => {
      return (
        <Stack key={field.name}>
          <AccountDetailsProperty {...field} />
          <Divider />
        </Stack>
      );
    })}
  </Stack>
);

const AccountDetailsFooter = ({
  onClickEdit,
  onResetPassword,
}: {
  onClickEdit: () => void;
  onResetPassword: () => void;
}) => {
  const auth = useAuth();
  const navigate = useNavigate();
  return (
    <Stack direction="row" justifyContent="center">
      <Button onClick={onClickEdit}>
        <SR package="account" id="editProfileButtonText" />
      </Button>
      {auth.authType === 'PASSWORD' && (
        <Button
          onClick={() => {
            onResetPassword();
            analytics.track(Event.RESET_PASSWORD);
          }}
        >
          <SR package="account" id="resetPasswordButtonText" />
        </Button>
      )}
      <Button
        onClick={async () => {
          await auth.signOut();
          analytics.track(Event.SIGN_OUT);
          analytics.reset();
          navigate(routes.login());
        }}
      >
        <SR package="account" id="signOutButtonText" />
      </Button>
    </Stack>
  );
};

export const AccountDetailsPage = () => {
  const { careNavigator } = useCareNavigator();
  const navigate = useNavigate();
  const goBack = useGoBack();
  const auth = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const { setCareNavigator } = useCareNavigator();

  const fields: AccountDetailsProps[] = [
    { name: 'firstNameLabel', value: careNavigator.firstName },
    { name: 'lastNameLabel', value: careNavigator.lastName },
    { name: 'preferredNameLabel', value: careNavigator.preferredName, fallback: 'preferredNameFallback' },
    { name: 'jobTitleLabel', value: careNavigator.jobTitle, fallback: 'jobTitleFallback' },
    { name: 'emailLabel', value: careNavigator.email },
  ];

  return (
    <FixedWidthLayout maxWidth="sm">
      <Stack>
        <AccountDetailsHeader isEditing={isEditing} onClickBack={() => (isEditing ? setIsEditing(false) : goBack())} />

        {isEditing ? (
          <EditAccountDetailsBody
            careNavigator={careNavigator}
            onCancel={() => setIsEditing(false)}
            onSave={updatedCareNavigator => {
              setIsEditing(false);
              setCareNavigator({ ...careNavigator, ...updatedCareNavigator });
              analytics.track(Event.EDIT_PROFILE);
            }}
          />
        ) : (
          <Stack spacing="sm">
            <AccountDetailsBody fields={fields} />
            <AccountDetailsFooter
              onClickEdit={() => setIsEditing(true)}
              onResetPassword={() => {
                auth.forgotPassword(careNavigator.email).then(() => {
                  navigate(routes.resetPasswordConfirmation({ email: careNavigator.email }));
                });
              }}
            />
          </Stack>
        )}
      </Stack>
    </FixedWidthLayout>
  );
};
