export const headingText = 'Change password';
export const changePasswordText = 'Change password';
export const loginPageText = 'Login';
export const passwordLabel = 'Password';
export const unknownErrorText = 'Error logging in. Please reach out to support';
export const invalidPasswordText =
  'Password must be at least 12 characters, contain at least 1 number, 1 uppercase letter, and 1 lowercase letter';
export const requireLower = 'One lowercase letter';
export const requireUpper = 'One uppercase letter';
export const minLength = '{length:length} character minimum';
export const requireNumber = 'One number';
export const passwordStrengthLabel = 'Password strength';
export const passwordStrengthLabelsEmpty = 'Enter password';
export const passwordStrengthLabelsFair = 'Okay';
export const passwordStrengthLabelsStrong = 'Strong';
export const passwordStrengthLabelsLong = 'Too long';
export const passwordStrengthLabelsShort = 'Too short';
export const passwordStrengthLabelsWeak = 'Weak';
export const passwordStrengthLabelsMissingRequirement = 'Missing requirement';
