import React from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthChangePassword } from '@garner-health/components-auth';
import { FixedWidthLayout } from '@garner-health/components-common';
import { useStringResource } from '@garner-health/lib-ui-content-management';
import { analytics, Event } from '~/analytics';
import { routes } from '~/router/routes';

export const ResetPasswordPage = () => {
  const navigate = useNavigate();

  const useMinLengthText = (length: number) =>
    useStringResource('resetPasswordPage', 'minLength', {
      length: () => length.toString(),
    });

  return (
    <FixedWidthLayout maxWidth="sm">
      <AuthChangePassword
        headingText={useStringResource('resetPasswordPage', 'headingText')}
        changePasswordText={useStringResource('resetPasswordPage', 'changePasswordText')}
        loginPageText={useStringResource('resetPasswordPage', 'loginPageText')}
        passwordLabel={useStringResource('resetPasswordPage', 'passwordLabel')}
        loginPageLink={routes.login()}
        signInCallback={email => {
          navigate(routes.home());
          analytics.track(Event.RESET_PASSWORD, { email });
        }}
        errorText={{
          UNKNOWN: useStringResource('resetPasswordPage', 'unknownErrorText'),
          INVALID_PASSWORD: useStringResource('resetPasswordPage', 'invalidPasswordText'),
        }}
        requireLower
        requireNumber
        requireUpper
        minLength={12}
        strengthLabels={{
          requireLower: useStringResource('resetPasswordPage', 'requireLower'),
          requireUpper: useStringResource('resetPasswordPage', 'requireUpper'),
          minLength: useMinLengthText,
          requireNumber: useStringResource('resetPasswordPage', 'requireNumber'),
          passwordStrengthLabel: `${useStringResource('resetPasswordPage', 'passwordStrengthLabel')}:`,
          passwordStrengthLabels: {
            empty: useStringResource('resetPasswordPage', 'passwordStrengthLabelsEmpty'),
            fair: useStringResource('resetPasswordPage', 'passwordStrengthLabelsFair'),
            strong: useStringResource('resetPasswordPage', 'passwordStrengthLabelsStrong'),
            long: useStringResource('resetPasswordPage', 'passwordStrengthLabelsLong'),
            short: useStringResource('resetPasswordPage', 'passwordStrengthLabelsShort'),
            weak: useStringResource('resetPasswordPage', 'passwordStrengthLabelsWeak'),
            missingRequirement: useStringResource('resetPasswordPage', 'passwordStrengthLabelsMissingRequirement'),
          },
        }}
      />
    </FixedWidthLayout>
  );
};
