import { createContext, useContext } from 'react';
import { Facility, Professional } from '~/clients';
import logger from '~/logging';

const log = logger(__filename);

interface BaseDetailContext {
  locationId: string;
  specialty: string;
  networkId: string;
}

interface ProfessionalDetailContext extends BaseDetailContext {
  professional: Professional;
  facility?: never;
}
export interface FacilityDetailContext extends BaseDetailContext {
  professional?: never;
  facility: Facility;
}
export interface BasicProviderDetailContext extends Partial<BaseDetailContext> {
  professional?: Professional;
  facility?: Facility;
}

export type ProviderDetailContextType = ProfessionalDetailContext | FacilityDetailContext;

interface BaseDetailsPageAction {
  locationId?: string;
  specialty?: string;
  networkId?: string;
}

interface UpdateProfessionalAction extends BaseDetailsPageAction {
  type: 'updateProfessional';
  professional: Professional;
  facility?: never;
}

interface UpdateFacilityAction extends BaseDetailsPageAction {
  type: 'updateFacility';
  facility: Facility;
  professional?: never;
}
interface UpdateLocationAction extends BaseDetailsPageAction {
  type: 'updateLocation';
  locationId: string;
}

export type DetailsPageAction = UpdateProfessionalAction | UpdateFacilityAction | UpdateLocationAction;

const dispatchBase: React.Dispatch<DetailsPageAction> = value => {
  return value;
};

export const ProviderDetailContext = createContext<BasicProviderDetailContext>({});

export const ProviderDetailDispatchContext = createContext(dispatchBase);

export function useProviderDetails(): ProviderDetailContextType | null {
  const { professional, facility, locationId, specialty, networkId } = useContext(ProviderDetailContext);
  if ((!professional && !facility) || !locationId || !specialty || !networkId) {
    log.error(
      {
        professionalId: professional?.id,
        facilityId: facility?.id,
        locationId,
        strSpecialty: specialty,
        networkId,
      },
      'missing provider detail context',
    );
    return null;
  }
  if (professional) {
    return { professional, facility: undefined, locationId, specialty, networkId };
  }
  if (facility) {
    return { facility, locationId, specialty, networkId };
  }
  return null;
}

export function useProviderDetailDispatch() {
  return useContext(ProviderDetailDispatchContext);
}

export function providerDetailsReducer(
  details: BasicProviderDetailContext,
  action: DetailsPageAction,
): BasicProviderDetailContext {
  switch (action.type) {
    case 'updateProfessional': {
      return {
        ...details,
        ...action,
        facility: undefined,
      };
    }
    case 'updateFacility': {
      return {
        ...details,
        ...action,
        professional: undefined,
      };
    }
    case 'updateLocation': {
      return {
        ...details,
        locationId: action.locationId,
      };
    }
  }
}
