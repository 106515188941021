/**
 * Determines whether the response has a body given the content length header and transfer encoding header.
 * @param contentLengthHeader Content length header indicates the length of the body
 * @param transferEncodingHeader Transfer encoding header is present if content length is too large for one chunk.
 * These two are generally mutually exclusive headers.
 * @returns boolean
 */
const transferEncodingValues = ['chunked', 'gzip', 'compress', 'deflate', 'identity'];
export function hasResponseBody(contentLengthHeader?: string | null, transferEncodingHeader?: string | null): boolean {
  return !!(
    Number(contentLengthHeader) > 0 ||
    (transferEncodingHeader && transferEncodingValues.includes(transferEncodingHeader))
  );
}
