import React, { useEffect, useState } from 'react';
import { getConfig, getContent } from './content';
import { useIsMounted } from './hooks';
const errorSymbol = Symbol();
export const ContentContext = React.createContext({});
export function useContent() {
    return React.useContext(ContentContext);
}
export async function getApplicationStringPackage(app, parentContext, packages) {
    const parentPackages = new Set(Object.keys(parentContext));
    const newPackages = packages.filter(p => !parentPackages.has(p));
    if (!newPackages.length)
        return parentContext;
    const query = newPackages.length === 1 ? { package: newPackages[0] } : { 'package[in]': newPackages.join(',') };
    const entries = await getContent({ app, contentType: 'applicationStringV2', fieldQuery: query });
    const store = { ...parentContext };
    for (const pkg of packages)
        store[pkg] = {};
    for (const entry of entries)
        store[entry.package][entry.id] = entry.stringValue;
    return store;
}
export function ContentProvider({ errorPage, children, ...params }) {
    const parentContext = useContent();
    const isMounted = useIsMounted();
    const [isLoading, setLoading] = useState(true);
    const [contentStore, setContentStore] = useState();
    useEffect(() => {
        setLoading(true);
        getApplicationStringPackage(getConfig().applicationStringAppId, parentContext, 'package' in params ? [params.package] : params.packages).then(res => {
            if (!isMounted())
                return;
            setContentStore(res);
            setLoading(false);
        }, (err) => {
            if (!isMounted())
                return;
            setContentStore({ [errorSymbol]: err });
            setLoading(false);
        });
    }, []);
    if (!contentStore || isLoading)
        return null;
    if (isError(contentStore))
        return errorPage !== null && errorPage !== void 0 ? errorPage : getConfig().errorPage;
    return React.createElement(ContentContext.Provider, { value: contentStore }, children);
}
function isError(obj) {
    return errorSymbol in obj;
}
