import React, { useState } from 'react';
import { Box, Button, SimpleForm, Spinner, Stack, TextInput } from '@garner-health/components-common';
import { SR, useStringResource } from '@garner-health/lib-ui-content-management';
import { analytics, Event } from '~/analytics';
import { CareNavigator, careNavigatorClient } from '~/clients';
import { pick } from '~/util';

interface EditAccountDetailsBodyProps {
  careNavigator: CareNavigator;
  onCancel: () => void;
  onSave: (data: CareNavigator) => void;
}

export const EditAccountDetailsBody = ({ careNavigator, onCancel, onSave }: EditAccountDetailsBodyProps) => {
  const [isLoading, setIsLoading] = useState(false);
  async function onSubmit(data: CareNavigator) {
    analytics.track(Event.EDIT_PROFILE);
    setIsLoading(true);
    const resp = await careNavigatorClient.updateCareNavigator(careNavigator.id, data);
    if (resp.success) onSave(data);
    setIsLoading(false);
  }

  return (
    <SimpleForm
      defaultValues={pick(careNavigator, ['firstName', 'lastName', 'jobTitle', 'preferredName'])}
      onSubmit={onSubmit}
    >
      <Stack>
        <TextInput
          name="firstName"
          label={useStringResource('account', 'editFirstNamePlaceholder')}
          validation={{ required: true }}
          fullWidth
        />
        <TextInput
          name="lastName"
          label={useStringResource('account', 'editLastNamePlaceholder')}
          validation={{ required: true }}
          fullWidth
        />
        <TextInput
          name="preferredName"
          label={useStringResource('account', 'editPreferredNamePlaceholder')}
          fullWidth
        />
        <TextInput name="jobTitle" label={useStringResource('account', 'editJobTitlePlaceholder')} fullWidth />
        <Box>
          <Button type="submit" variant="primary" disabled={isLoading} fullWidth>
            {isLoading ? <Spinner variant="dark" /> : <SR package="account" id="saveButtonText" />}
          </Button>
        </Box>
        <Box>
          <Button type="reset" variant="secondary" onClick={onCancel} disabled={isLoading} fullWidth>
            <SR package="account" id="cancelButtonText" />
          </Button>
        </Box>
      </Stack>
    </SimpleForm>
  );
};
