import React from 'react';
// Not supported shows the same SVG as not found
import NotSupportedSvg from 'jsx:~/assets/images/empty-search-results.svg';
import { Typography } from '@garner-health/components-common';
import { SR, useStringResource } from '@garner-health/lib-ui-content-management';
import { Event } from '~/analytics';
import { SupportCta, SvgWithSubText } from '../common';

export const NotSupportedSearch = () => {
  const header = useStringResource('search', 'notSupportedHeaderText');

  return (
    <SvgWithSubText
      svg={<NotSupportedSvg role="img" aria-label={header} />}
      header={header}
      subHeader={
        <Typography variant="body1" color="common.muted">
          <SR
            package="search"
            id="notSupportedHeaderSubtext"
            interpolate={supportEmail => <SupportCta supportEmail={supportEmail} event={Event.CARE_GOAL_SUPPORT} />}
          />
        </Typography>
      }
    />
  );
};
