import { createClient } from 'contentful';
import { Cache } from './cache';
// This is the maximum allowed. Seriously, that's a lot of strings.
// https://www.contentful.com/developers/docs/references/content-delivery-api/#/reference/search-parameters/limit
const LIMIT_ENTRIES = 1000;
// The numbers of levels to retrieve for linked/embedded entries and assets.
const EMBEDDED_ENTRY_LEVELS = 1;
const DEFAULT_CACHE_TTL_MS = 300000;
let client;
let config;
let cache;
export function init(initConfig) {
    var _a;
    client = createClient(initConfig);
    config = {
        ...initConfig,
        log: initConfig.log.child({ module: 'lib-ui-content-management' }),
    };
    cache = new Cache((_a = config.cacheTtl) !== null && _a !== void 0 ? _a : DEFAULT_CACHE_TTL_MS);
}
export function getConfig() {
    if (!config) {
        throw new Error('Content config is not set. Have you called `init()` from this module?');
    }
    return config;
}
function getClient() {
    if (!client) {
        throw new Error('Content client is not initialized. Have you called `init()` from this module?');
    }
    return client;
}
function getCache() {
    if (!cache) {
        throw new Error('Content cache is not initialized. Have you called `init()` from this module?');
    }
    return cache;
}
export async function getContent({ app, contentType, fieldQuery, order, locale, }) {
    const query = {
        content_type: contentType,
        limit: LIMIT_ENTRIES,
        include: EMBEDDED_ENTRY_LEVELS,
    };
    if (order)
        query.order = order;
    for (const field in fieldQuery) {
        query[`fields.${field}`] = fieldQuery[field];
    }
    if (app) {
        // Do this after other fieldQuery fields are set so they can't overwrite it
        query[`fields.app`] = app;
    }
    if (locale) {
        query.locale = locale;
    }
    const cacheKey = JSON.stringify(query);
    const cacheValue = getCache().getValue(cacheKey);
    if (cacheValue) {
        return cacheValue;
    }
    const result = await getClient()
        .getEntries(query)
        .catch(err => {
        getConfig().log.error({ err, strContentType: contentType, strApp: app, context: { fieldQuery, order, locale } }, 'Error getting content');
        throw err;
    });
    if (result.total > LIMIT_ENTRIES) {
        throw new Error(`Field query returned more than the maximum of ${LIMIT_ENTRIES} entries allowed `);
    }
    const response = result.items.map(item => item.fields);
    getCache().setValue(cacheKey, response);
    return response;
}
