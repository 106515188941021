/// <reference lib="dom.iterable" />

import * as C from '../core';
import { hasResponseBody } from './common';

export const fetchAdapter: C.NetworkAdapter = async req => {
  const response = await fetch(req.url, {
    method: req.method,
    headers: req.headers as Record<string, string>,
    body: getRequestBody(req),
  });

  return {
    url: response.url,
    status: response.status,
    headers: C.normalizeHeaders(response.headers.entries()),
    ...(await getResponseBody(response)),
  };
};

function getRequestBody(request: C.Request): BodyInit | undefined {
  if ('body' in request) {
    return request.body;
  }

  if ('json' in request) {
    return JSON.stringify(request.json);
  }

  return undefined;
}

async function getResponseBody(response: Response): Promise<C.Body | undefined> {
  if (!hasResponseBody(response.headers.get('content-length'), response.headers.get('transfer-encoding'))) {
    return undefined;
  }

  return C.isJsonMedia(response.headers.get('content-type'))
    ? { json: await response.json() }
    : // Response is a subclass of Blob and has a better API for getting data out.
      { body: response };
}
