export const accountHeader = 'Account';
export const firstNameLabel = 'First name';
export const lastNameLabel = 'Last Name';
export const preferredNameLabel = 'Preferred name';
export const preferredNameFallback = 'Not specified';
export const jobTitleLabel = 'Job title';
export const jobTitleFallback = 'Not specified';
export const emailLabel = 'Email';
export const editProfileButtonText = 'Edit profile';
export const editProfileHeaderText = 'Edit profile';
export const resetPasswordButtonText = 'Reset password';
export const signOutButtonText = 'Sign out';
export const saveButtonText = 'Save';
export const cancelButtonText = 'Cancel';
export const editFirstNamePlaceholder = 'First name';
export const editLastNamePlaceholder = 'Last name';
export const editPreferredNamePlaceholder = 'Preferred name (Optional)';
export const editJobTitlePlaceholder = 'Job title (Optional)';
