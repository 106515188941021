import React, { ReactNode } from 'react';
import { Box, Stack, Typography } from '@garner-health/components-common';

interface SvgWithSubTextProps {
  svg: ReactNode;
  header: string;
  subHeader: ReactNode;
}

export const SvgWithSubText = ({ svg, header, subHeader }: SvgWithSubTextProps) => (
  <Stack>
    <Box textAlign="center" paddingY="xl">
      {svg}
      <Typography variant="body1">{header}</Typography>
      {typeof subHeader === 'string' ? (
        <Typography variant="body1" color="common.muted">
          {subHeader}
        </Typography>
      ) : (
        subHeader
      )}
    </Box>
  </Stack>
);
