import React, { useEffect, useState } from 'react';
import { Box, Panel, Spinner } from '@garner-health/components-common';
import { useFlags } from '@garner-health/lib-ui-feature-flags';
import { ProviderResultItem } from '~/clients';
import {
  DataSecurityDialog,
  NoResults,
  NotSupportedSearch,
  SearchFormData,
  SearchResults,
  StartSearch,
} from '~/components/provider-search';
import { useSearchFormData } from '~/hooks/use-search-form-data';
import { ResponseSetType, useSearchFormResults } from '~/hooks/use-search-form-results';
import { SearchForm } from '~/components/provider-search/search-form';

function isTieredNetwork(networkId: string | undefined): boolean {
  if (!networkId) return false;
  return /^.*-tier\d+$/.test(networkId);
}

const MainContent = ({
  formData,
  providers,
  responseSetType,
  loadingSearch,
}: {
  formData: SearchFormData | null;
  providers: ProviderResultItem[] | null;
  responseSetType: ResponseSetType | null;
  loadingSearch: boolean;
}) => {
  if (!formData) return <StartSearch />;
  //TODO: make types stricter so we know searchResults has a value when !loadingSearch
  // https://app.asana.com/0/1203374383582495/1205127243912400/f
  if (!providers || loadingSearch) return <Spinner variant="dark" />;
  if (responseSetType === 'not_supported') return <NotSupportedSearch />;

  const tieredNetwork = isTieredNetwork(formData.network.value);
  if (!providers.length || !responseSetType) return <NoResults isTieredNetwork={tieredNetwork} />;
  return (
    <SearchResults
      providers={providers}
      formData={formData}
      responseSetType={responseSetType}
      isTieredNetwork={tieredNetwork}
    />
  );
};

const LoadingPage = () => (
  <Panel>
    <Panel.Header>
      <Box padding="sm" />
    </Panel.Header>
    <Panel.Main>
      <Spinner variant="dark" />
    </Panel.Main>
  </Panel>
);

export const SearchPage = () => {
  const { data: formData, loading: loadingFormData, onNewFormData, shouldReset } = useSearchFormData();
  const { showAdditionalProviders } = useFlags();
  const { data: searchResults, loading: loadingSearch } = useSearchFormResults({
    formData,
    showAdditionalProviders,
  });
  const [hasLoadedPage, setHasLoadedPage] = useState(false);

  const providers = searchResults?.providers ?? null;
  const responseSetType = searchResults?.responseSetType ?? null;

  useEffect(() => {
    if (!loadingFormData) {
      // once we've loaded the form once, we've loaded the page
      setHasLoadedPage(true);
    }
  }, [loadingFormData]);

  if (!hasLoadedPage) {
    return <LoadingPage />;
  }

  return (
    <>
      <Panel containScroll>
        <Panel.Header divider paddingTop="xs">
          <SearchForm
            initialValues={formData}
            onSubmit={onNewFormData}
            isDisabled={loadingSearch}
            shouldReset={shouldReset}
          />
        </Panel.Header>

        <Panel.Main paddingBottom="none" paddingTop="none">
          <MainContent
            formData={formData}
            providers={providers}
            responseSetType={responseSetType}
            loadingSearch={loadingSearch}
          />
        </Panel.Main>
      </Panel>
      <DataSecurityDialog />
    </>
  );
};
