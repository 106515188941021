export const ratingStarsText = '{rating:rating} stars';
export const stepIndicatorText = '{current:current} out of {total:total}';
export const autocompleteFetchErrorText = 'An error occurred while retrieving the results';
export const selectInputClearButtonLabel = 'Clear';
export const baseTextInputClearButtonLabel = 'Clear';
export const formValidationGenericLabel = 'This field';
export const formValidationUnknownError = 'Unknown error';
export const formValidationInvalidEmail = '{label:label} has an invalid email';
export const formValidationRequired = '{label:label} is required';
export const tableSortLabelAsc = 'Sorted ascending';
export const tableSortLabelDesc = 'Sorted descending';
