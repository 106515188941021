import React from 'react';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import GarnerDataProWordmark from 'jsx:~/assets/images/garner-datapro-logo.svg';
import { useAuth } from '@garner-health/components-auth';
import { Box, IconWrapper, Link, Stack } from '@garner-health/components-common';
import { SR } from '@garner-health/lib-ui-content-management';
import { routes } from '~/router/routes';

const Wordmark = ({ isLoggedIn }: { isLoggedIn: boolean }) => {
  // eslint-disable-next-line react/forbid-component-props -- SVG's need to be 'display:block' inside an anchor
  const wordMark = <GarnerDataProWordmark role="img" aria-label={'header'} style={{ display: 'block' }} />;

  return isLoggedIn ? <Link to={routes.home()}>{wordMark}</Link> : wordMark;
};

export const AppHeader = ({ hideAccountButton }: { hideAccountButton?: boolean }) => {
  const auth = useAuth();
  const isLoggedIn = auth.state === 'LOGGED_IN';

  return (
    <Box paddingX="xs">
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Box>
          <Wordmark isLoggedIn={isLoggedIn} />
        </Box>

        {isLoggedIn && !hideAccountButton && (
          <Box>
            <Link to={routes.account()}>
              <Stack direction="row" spacing="xs" alignItems="center">
                <IconWrapper>
                  <AccountCircleOutlinedIcon />
                </IconWrapper>
                <SR package="main" id="accountButtonText" />
              </Stack>
            </Link>
          </Box>
        )}
      </Stack>
    </Box>
  );
};
