import * as resourcePackages from './resource-packages';

type LocalResourcePackages = typeof resourcePackages;
type MergedResourcePackages = {
  [K in keyof LocalResourcePackages]: LocalResourcePackages[K];
};

declare module '@garner-health/lib-ui-content-management' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface ResourcePackages extends MergedResourcePackages {}
}
