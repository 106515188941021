import React from 'react';
import EmptySearchResultsSvg from 'jsx:~/assets/images/empty-search-results.svg';
import { Stack } from '@garner-health/components-common';
import { useStringResource } from '@garner-health/lib-ui-content-management';
import { SvgWithSubText } from '../common';
import { TieredNetworkCallout } from './tiered-network-callout';

export const NoResults = ({ isTieredNetwork }: { isTieredNetwork?: boolean }) => {
  const header = useStringResource('search', 'noResultsHeaderText');
  const subHeader = useStringResource('search', 'noResultsSubHeaderText');

  return isTieredNetwork ? (
    <Stack justifyContent="center">
      <TieredNetworkCallout />
    </Stack>
  ) : (
    <SvgWithSubText
      svg={<EmptySearchResultsSvg role="img" aria-label={header} />}
      header={header}
      subHeader={subHeader}
    />
  );
};
