import { adapters } from '@garner-health/api-client';
import { getAuth } from '@garner-health/components-auth';
import createLogger from '~/logging';

export const baseClientOptions = { baseUrl: `${location.origin}/api`, adapter: adapters.fetch };
const log = createLogger(__filename, { component: 'error-boundary' });

export async function getDefaultHeaders(): Promise<Record<string, string>> {
  try {
    const auth = getAuth();
    const token = await auth.getIdToken();
    return {
      Authorization: `Bearer ${token}`,
      'Garner-Application-Version': GARNER_VERSION,
      'Garner-Application-ID': 'ui-datapro',
    };
  } catch (err) {
    log.error({ err }, 'Error getting JWT');
    return {};
  }
}

/**
 * Turn an array into multiple arrays of a maximum specified size
 * @param array array to split up
 * @param maxChunkSize max size of each resulting chunk
 */
export function chunkArray<T>(array: T[], maxChunkSize: number): T[][] {
  return Array.from({ length: Math.ceil(array.length / maxChunkSize) }, (_, index) =>
    array.slice(index * maxChunkSize, (index + 1) * maxChunkSize),
  );
}
