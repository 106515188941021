export function pick<T, K extends keyof T>(obj: T, keys: K[]): Pick<T, K> {
  const ret: any = {};
  for (const key of keys) {
    ret[key] = obj[key];
  }
  return ret;
}

export function omit<T, K extends keyof T>(obj: T, keys: K[]): Omit<T, K> {
  const ret = { ...obj };
  for (const key of keys) {
    delete ret[key];
  }
  return ret;
}

export function capitalize(input: string): string {
  return input.charAt(0).toUpperCase() + input.slice(1);
}

export function isEmpty(obj: object | unknown[] | null | undefined): boolean {
  if (obj == null) return true;
  if (Array.isArray(obj) && obj.length === 0) return true;
  if (typeof obj === 'object' && Object.keys(obj).length === 0) return true;
  return false;
}

/**
 * Removes all fields from an object where the value is null, undefined, or an empty string
 */
export function removeEmptyFields(obj: Record<string, unknown>): Record<string, unknown> {
  return Object.fromEntries(Object.entries(obj).filter(([, value]) => !(value == null || value === '')));
}
