import constants from 'app-constants';
import { AuthTypeConfig, AuthTypeResolverError, getAuth } from '@garner-health/components-auth';
import { failure, Response, success } from '@garner-health/lib-ts-common';
import { identityClient } from '~/clients';
import createLogger from '~/logging';

const log = createLogger(__filename);

type Scope = 'core' | 'core_plus' | 'core_demo' | 'core_plus_demo';

interface Claims {
  email: string;
  clientId: number;
  careNavigatorId: number;
  networkIds: string[];
  scope: Scope;
}

export async function getTokenClaims(): Promise<Claims> {
  const claims = await getAuth().getTokenClaims();
  return {
    email: claims.email,
    clientId: Number(claims['custom:client_id']),
    careNavigatorId: Number(claims['custom:care_navigator_id']),
    networkIds: JSON.parse(claims['custom:network_ids']),
    scope: claims['custom:scope'].split(':')[1],
  };
}

export interface ScopeInfo {
  isDemo: boolean;
  plan: 'core' | 'core_plus';
}

const scopeInfoMap: Record<Scope, ScopeInfo> = {
  core: { isDemo: false, plan: 'core' },
  core_plus: { isDemo: false, plan: 'core_plus' },
  core_demo: { isDemo: true, plan: 'core' },
  core_plus_demo: { isDemo: true, plan: 'core_plus' },
};

export function getScopeInfo(scope: Scope): ScopeInfo {
  return scopeInfoMap[scope];
}

export async function authTypeResolver(emailDomain: string): Promise<Response<AuthTypeConfig, AuthTypeResolverError>> {
  const userPoolId = constants.authConfig.cognito.userPoolId;

  try {
    const providers = await identityClient.getIdentityProviders({ emailDomain, userPoolId });

    if (providers.length > 1) {
      log.warn({ userPoolId, strEmailDomain: emailDomain }, 'Only one identity provider was expected');
    }

    if (providers.length) {
      return success({
        type: 'SSO',
        providerName: providers[0].name,
      });
    }

    return success({
      type: 'PASSWORD',
    });
  } catch (err) {
    log.error({ err, userPoolId, strEmailDomain: emailDomain }, 'Error getting identity providers');
    return failure('UNKNOWN');
  }
}
