import addMinutes from 'date-fns/addMinutes';
import format from 'date-fns/format';
import startOfDay from 'date-fns/startOfDay';

export function timeOfDay(date: number | Date) {
  return format(date, 'h:mm a');
}

/**
 * Takes a time as a number of minutes since midnight and displays in `h:mm a` format
 * @example
 * @input
 * 540
 * @returns
 * '9:00 AM'
 */
export function displayTime(minutesSinceMidnight: number) {
  return timeOfDay(addMinutes(startOfDay(new Date()), minutesSinceMidnight));
}
