import React from 'react';
import StartNewSearchSvg from 'jsx:~/assets/images/start-new-search.svg';
import { useStringResource } from '@garner-health/lib-ui-content-management';
import { SvgWithSubText } from '../common';

export const StartSearch = () => {
  const header = useStringResource('search', 'startNewSearchHeaderText');

  return (
    <SvgWithSubText
      svg={<StartNewSearchSvg role="img" aria-label={header} />}
      header={header}
      subHeader={useStringResource('search', 'startNewSearchSubHeaderText')}
    />
  );
};
