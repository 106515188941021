import { ClientOptions, Execution, FluentExecution, OperationInput } from '@garner-health/api-client-core';
import type { Headers } from '@garner-health/api-client-core';
export type CreateRecommendationRequest = {
  headers?: Headers;
  json: {
    account_id: number;
    source: 'CONCIERGE_WEB' | 'CONCIERGE_API' | 'CONCIERGE_VOICE' | 'CONCIERGE_EMAIL';
    providers: {
      provider_id: string;
      specialty?: string;
      first_name?: string;
      last_name?: string;
      npi: string;
      facility_name?: string;
      cost_quality_score?: number;
      type?: 'physician' | 'facility';
    }[];
    [k: string]: unknown;
  } & (
    | {
        category: 'TOP_PROVIDER';
        reason: 'CONCIERGE_R12N' | 'MEMBER_REQUEST_NEW' | 'MEMBER_REQUEST_EXISTING' | 'MEMBER_REQUEST_UNKNOWN';
        [k: string]: unknown;
      }
    | {
        category: 'AUTO_APPROVAL_SPECIALTY';
        reason:
          | 'CONCIERGE_R12N'
          | 'MEMBER_REQUEST_NEW'
          | 'MEMBER_REQUEST_EXISTING'
          | 'MEMBER_REQUEST_UNKNOWN'
          | 'LEVEL_MID'
          | 'FACILITIES';
        [k: string]: unknown;
      }
    | {
        category: 'CONTINUITY_OF_CARE';
        reason:
          | 'PREGNANCY_RELATED'
          | 'CARE_FOR_CHILD'
          | 'TERMINAL_ILLNESS'
          | 'ONGOING_TREATMENT'
          | 'SERIOUS_CHRONIC_CONDITION'
          | 'ALREADY_PLANNED_PROCEDURE'
          | 'PSYCHOTHERAPY'
          | 'PHYSICAL_THERAPY'
          | 'MEDICATION'
          | 'FOLLOW_UP_SERIOUS_PROCEDURE'
          | 'FOLLOW_UP_EMERGENCY'
          | 'DIAGNOSING_COMPLEX_CONDITION';
        [k: string]: unknown;
      }
    | {
        category: 'PCP_ER_COVERAGE';
        reason: 'EXISTING_PCP' | 'URGENT_CARE';
        [k: string]: unknown;
      }
    | {
        category: 'AFFILIATED_PHYSICIAN';
        [k: string]: unknown;
      }
    | {
        category: 'EXCEPTION_OTHER';
        reason:
          | 'CLIENT_REQUEST'
          | 'APPROVED_EXPANDED_SEARCH'
          | 'TIME_SENSITIVE'
          | 'VIP_REQUEST'
          | 'EXCEPTION_DATA'
          | 'EXCEPTION_OTHER';
        [k: string]: unknown;
      }
  );
};
export type CreateRecommendationResponses = {
  201: {
    status: 201;
    url: string;
    headers: Headers;
    json: {
      id: number;
      account_id: number;
      created_at: string;
    };
  };
};
export type PostUrl = '/recommendations';
type RequestsByMethodAndPath = {
  'POST /recommendations': CreateRecommendationRequest;
};
type ResponsesByMethodAndPath = {
  'POST /recommendations': CreateRecommendationResponses;
};
export class RecommendationServiceV2 {
  constructor(private readonly options: ClientOptions) {}
  post<Url extends PostUrl>(
    url: Url,
    input: RequestsByMethodAndPath[`POST ${Url}`],
  ): FluentExecution<ResponsesByMethodAndPath[`POST ${Url}`]>;
  post(url: string, input: OperationInput = {}): FluentExecution<Record<number, any>> {
    return new Execution(this.options, 'POST', url, input, 2);
  }
}
