import type { FeatureFlagDefinition } from '@garner-health/lib-ui-feature-flags';

interface DataProFeatureFlags {
  warningBanner: boolean;
  showAdditionalProviders: boolean;
}

declare module '@garner-health/lib-ui-feature-flags' {
  // Need to use an interface so declaration merging works, using a type alias doesn't acheive the same effect
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface FeatureFlags extends DataProFeatureFlags {}
}

export const flagDefinitions: FeatureFlagDefinition[] = [
  {
    key: 'warningBanner',
    name: 'Warning Banner',
    description: 'Shows a banner at the top of the DataPro app for reporting either incidents or scheduled maintenance',
  },
  {
    key: 'showAdditionalProviders',
    name: 'Show Additional Providers',
    description: 'Alters specialty requests to show up to 30 top and not top providers which meet the search criteria',
  },
];

export const defaults: DataProFeatureFlags = {
  warningBanner: false,
  showAdditionalProviders: false,
};
