import React from 'react';
import {
  RowRenderResult,
  Table,
  TableColumnDefinition,
  TableContext,
  TableDefinition,
  Typography,
} from '@garner-health/components-common';
import { SR, useStringResource } from '@garner-health/lib-ui-content-management';
import { ProviderResultItem, ProvidersResponseSetType } from '~/clients';
import { useSpecialtyLabel } from '~/contexts';
import { providerKeyResolver, roundToDecimals } from '~/util';

export interface ProvidersListProps {
  searchType: ProvidersResponseSetType;
  providers: ProviderResultItem[];
  configureRow?: (item: ProviderResultItem, key: string) => RowRenderResult;
  selectedKey?: string;
}

function getProviderName(
  firstName: string | undefined,
  lastName: string | undefined,
  organizationName: string | undefined,
) {
  if (firstName || lastName) return `${firstName} ${lastName}`;
  return organizationName;
}

export const ProvidersTable = ({ searchType, providers, configureRow, selectedKey }: ProvidersListProps) => {
  const specialtyLabel = useSpecialtyLabel();

  const columns: TableColumnDefinition<ProviderResultItem>[] = [
    {
      key: 'nameAndSpecialty',
      label: useStringResource('search', 'providerNameAndSpecialtyColumnLabel'),
      render: item => {
        const specialties = item.specialties.map(specialtyLabel).join(', ');
        return (
          <>
            <Typography variant="body1">
              {getProviderName(item.firstName, item.lastName, item.organizationName)}
            </Typography>

            <Typography variant="body1" color="common.muted">
              {specialties}
            </Typography>
          </>
        );
      },
    },
    {
      key: 'distance',
      label: useStringResource('search', 'providerDistanceColumnLabel'),
      render: item => {
        const distance = item.distanceMi;
        if (distance == null) return null;

        return (
          <SR
            package="search"
            id="providerDistanceMilesText"
            interpolate={{
              distance: () => roundToDecimals(distance, 2),
            }}
          />
        );
      },
    },
  ];

  const providerOverallScoreLabel = useStringResource('search', 'providerOverallScoreLabel');
  const notPreferredText = useStringResource('search', 'notPreferredText');
  if (searchType !== 'facility') {
    columns.push({
      key: 'overallScore',
      label: providerOverallScoreLabel,
      render: item => {
        return item.overallScore ? (
          <Typography variant="button" color="garner.primary.dark">
            {item.overallScore}
          </Typography>
        ) : (
          <Typography variant="body2">{notPreferredText}</Typography>
        );
      },
    });
  }

  const tableDefinition: TableDefinition<ProviderResultItem> = {
    columns,
    keyResolver: providerKeyResolver,
  };

  const tableContext: TableContext<ProviderResultItem> = {
    definition: tableDefinition,
    items: providers,
  };

  return <Table context={tableContext} configureRow={configureRow} selectedKey={selectedKey} />;
};
