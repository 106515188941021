import { AlertProps } from '@garner-health/components-common';
import { useStringResource } from '@garner-health/lib-ui-content-management';
import { useFlags } from '@garner-health/lib-ui-feature-flags';

export function useAppBanners(isDemo: boolean | undefined): AlertProps[] {
  const banners: AlertProps[] = [];
  const warningBannerText = useStringResource('main', 'warningBannerText');
  const demoBannerText = useStringResource('main', 'demoBannerText');
  const { warningBanner } = useFlags();

  if (isDemo) banners.push({ severity: 'info', body: demoBannerText, variant: 'compact' });
  if (warningBanner) banners.push({ severity: 'warning', body: warningBannerText });

  return banners;
}
