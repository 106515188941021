import { GarnerComponentConfig } from '@garner-health/components-common';
import { interpolateString, useStringResource } from '@garner-health/lib-ui-content-management';

function stepIndicatorAltText(current: number, total: number) {
  // TODO: refactor this to remove the warning https://app.asana.com/0/1203374383582495/1206039940246988/f
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useStringResource('theme', 'stepIndicatorText', {
    current: () => current.toString(),
    total: () => total.toString(),
  });
}

function ratingAltText(rating: number) {
  // TODO: refactor this to remove the warning https://app.asana.com/0/1203374383582495/1206039940246988/f
  // eslint-disable-next-line react-hooks/rules-of-hooks
  return useStringResource('theme', 'ratingStarsText', { rating: () => rating.toString() });
}

export function useComponentConfig(): GarnerComponentConfig {
  const formValidationInvalidEmail = useStringResource('theme', 'formValidationInvalidEmail');
  const formValidationRequired = useStringResource('theme', 'formValidationRequired');

  return {
    AutocompleteInput: {
      fetchErrorText: useStringResource('theme', 'autocompleteFetchErrorText'),
    },
    StepIndicator: {
      altText: {
        'en-US': stepIndicatorAltText,
        'es-ES': stepIndicatorAltText,
      },
    },
    Rating: {
      altText: {
        'en-US': ratingAltText,
        'es-ES': ratingAltText,
      },
    },
    SelectInput: {
      clearText: useStringResource('theme', 'selectInputClearButtonLabel'),
    },
    BaseTextInput: {
      clearText: useStringResource('theme', 'baseTextInputClearButtonLabel'),
    },
    Table: {
      sortLabels: {
        asc: useStringResource('theme', 'tableSortLabelAsc'),
        desc: useStringResource('theme', 'tableSortLabelDesc'),
      },
    },
    formValidation: {
      genericLabel: useStringResource('theme', 'formValidationGenericLabel'),
      unknownError: useStringResource('theme', 'formValidationUnknownError'),
      messages: {
        email: label => interpolateString(formValidationInvalidEmail, () => label),
        required: label => interpolateString(formValidationRequired, () => label),
      },
    },
  };
}
