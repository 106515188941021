import { ClientOptions, Execution, FluentExecution, OperationInput } from '@garner-health/api-client-core';
import type { Headers } from '@garner-health/api-client-core';
export type CreateIdentityRequest = {
  headers?: Headers;
  json: {
    id: string;
    user_pool_id: string;
    email: string;
    groups: string[];
  };
};
export type CreateIdentityResponses = {
  201: {
    status: 201;
    url: string;
    headers: Headers;
    json: {
      id: string;
      [k: string]: unknown;
    };
  };
  409: {
    status: 409;
    url: string;
    headers: Headers;
    json: {
      requestId?: string;
      message: string;
      error?: string;
      data?: {
        [k: string]: unknown;
      };
    };
  };
  422: {
    status: 422;
    url: string;
    headers: Headers;
    json: {
      requestId?: string;
      message: string;
      error?: string;
      data?: {
        [k: string]: unknown;
      };
    };
  };
};
export type GetIdentityRequest = {
  params: {
    id: string;
  };
  headers?: Headers;
  query: {
    user_pool_id: string;
  };
};
export type GetIdentityResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      id: string;
      user_pool_id: string;
      email: string;
      groups: string[];
      created_at: string;
      updated_at: string;
      email_verified?: boolean;
      phone_number?: string;
      phone_number_verified?: boolean;
      active_mfa_options?: ('SMS_MFA' | 'SOFTWARE_TOKEN_MFA')[];
    };
  };
  404: {
    status: 404;
    url: string;
    headers: Headers;
    json: {
      requestId?: string;
      message: string;
      error?: string;
      data?: {
        [k: string]: unknown;
      };
    };
  };
};
export type DeleteIdentityRequest = {
  params: {
    id: string;
  };
  headers?: Headers;
  query: {
    user_pool_id: string;
  };
};
export type DeleteIdentityResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      id: string;
      [k: string]: unknown;
    };
  };
  404: {
    status: 404;
    url: string;
    headers: Headers;
    json: {
      requestId?: string;
      message: string;
      error?: string;
      data?: {
        [k: string]: unknown;
      };
    };
  };
};
export type GetApiCredentialsRequest = {
  headers?: Headers;
  query: {
    client_id: number;
    offset?: number;
    limit?: number;
  };
};
export type GetApiCredentialsResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      total: number;
      offset: number;
      limit: number;
    } & {
      items: {
        api_client_id: string;
        garner_client_id: number;
        created_at: string;
      }[];
      [k: string]: unknown;
    };
  };
};
export type CreateApiCredentialsRequest = {
  headers?: Headers;
  json: {
    garner_client_id: number;
  };
};
export type CreateApiCredentialsResponses = {
  201: {
    status: 201;
    url: string;
    headers: Headers;
    json: {
      api_client_id: string;
      garner_client_id: number;
      created_at: string;
      api_client_secret: string;
    };
  };
};
export type DeleteApiCredentialsRequest = {
  params: {
    api_client_id: string;
  };
  headers?: Headers;
};
export type DeleteApiCredentialsResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      api_client_id: string;
    };
  };
  404: {
    status: 404;
    url: string;
    headers: Headers;
    json: {
      requestId?: string;
      message: string;
      error?: string;
      data?: {
        [k: string]: unknown;
      };
    };
  };
};
export type GetIdentityProvidersRequest = {
  headers?: Headers;
  query?: {
    user_pool_id?: string;
    email_domain?: string;
    client_id?: number;
    offset?: number;
    limit?: number;
  };
};
export type GetIdentityProvidersResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      total: number;
      offset: number;
      limit: number;
    } & {
      items: {
        id: string;
        client_id: number;
        name: string;
        user_pool_id: string;
      }[];
    };
  };
};
export type CreateIdentityProviderRequest = {
  headers?: Headers;
  json: {
    client_id: number;
    app_client_id: string;
    user_pool_id: string;
    email_domains?: string[];
    [k: string]: unknown;
  } & (
    | {
        type: 'OIDC';
        oidc_client_id: string;
        oidc_client_secret: string;
        oidc_issuer: string;
        [k: string]: unknown;
      }
    | {
        type: 'SAML';
        saml_metadata: string;
        [k: string]: unknown;
      }
  );
};
export type CreateIdentityProviderResponses = {
  201: {
    status: 201;
    url: string;
    headers: Headers;
    json: {
      id: string;
      name: string;
    };
  };
  409: {
    status: 409;
    url: string;
    headers: Headers;
    json: {
      requestId?: string;
      message: string;
      error?: string;
      data?: {
        [k: string]: unknown;
      };
    };
  };
};
export type GetIdentityProviderRequest = {
  params: {
    identity_provider_id: string;
  };
  headers?: Headers;
};
export type GetIdentityProviderResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json:
      | ({
          id: string;
          client_id: number;
          name: string;
          user_pool_id: string;
          email_domains: string[];
        } & {
          type: 'OIDC';
          oidc_client_id: string;
          oidc_client_secret: string;
          oidc_issuer: string;
          [k: string]: unknown;
        })
      | ({
          id: string;
          client_id: number;
          name: string;
          user_pool_id: string;
          email_domains: string[];
        } & {
          type: 'SAML';
          saml_metadata: string;
          [k: string]: unknown;
        });
  };
  404: {
    status: 404;
    url: string;
    headers: Headers;
    json: {
      requestId?: string;
      message: string;
      error?: string;
      data?: {
        [k: string]: unknown;
      };
    };
  };
};
export type UpdateIdentityProviderRequest = {
  params: {
    identity_provider_id: string;
  };
  headers?: Headers;
  json: {
    email_domains?: string[];
    [k: string]: unknown;
  } & (
    | {
        type: 'OIDC';
        oidc_client_id: string;
        oidc_client_secret: string;
        oidc_issuer: string;
        [k: string]: unknown;
      }
    | {
        type: 'SAML';
        saml_metadata: string;
        [k: string]: unknown;
      }
  );
};
export type UpdateIdentityProviderResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      id: string;
      name: string;
    };
  };
  404: {
    status: 404;
    url: string;
    headers: Headers;
    json: {
      requestId?: string;
      message: string;
      error?: string;
      data?: {
        [k: string]: unknown;
      };
    };
  };
  409: {
    status: 409;
    url: string;
    headers: Headers;
    json: {
      requestId?: string;
      message: string;
      error?: string;
      data?: {
        [k: string]: unknown;
      };
    };
  };
};
export type DeleteIdentityProviderRequest = {
  params: {
    identity_provider_id: string;
  };
  headers?: Headers;
};
export type DeleteIdentityProviderResponses = {
  200: {
    status: 200;
    url: string;
    headers: Headers;
    json: {
      id: string;
      name: string;
    };
  };
  404: {
    status: 404;
    url: string;
    headers: Headers;
    json: {
      requestId?: string;
      message: string;
      error?: string;
      data?: {
        [k: string]: unknown;
      };
    };
  };
};
export type PostUrl = '/identities' | '/api-credentials' | '/identity-providers';
export type GetUrl =
  | '/identities/{id}'
  | '/api-credentials'
  | '/identity-providers'
  | '/identity-providers/{identity_provider_id}';
export type DeleteUrl =
  | '/identities/{id}'
  | '/api-credentials/{api_client_id}'
  | '/identity-providers/{identity_provider_id}';
export type PutUrl = '/identity-providers/{identity_provider_id}';
type RequestsByMethodAndPath = {
  'POST /identities': CreateIdentityRequest;
  'GET /identities/{id}': GetIdentityRequest;
  'DELETE /identities/{id}': DeleteIdentityRequest;
  'GET /api-credentials': GetApiCredentialsRequest;
  'POST /api-credentials': CreateApiCredentialsRequest;
  'DELETE /api-credentials/{api_client_id}': DeleteApiCredentialsRequest;
  'GET /identity-providers': GetIdentityProvidersRequest;
  'POST /identity-providers': CreateIdentityProviderRequest;
  'GET /identity-providers/{identity_provider_id}': GetIdentityProviderRequest;
  'PUT /identity-providers/{identity_provider_id}': UpdateIdentityProviderRequest;
  'DELETE /identity-providers/{identity_provider_id}': DeleteIdentityProviderRequest;
};
type ResponsesByMethodAndPath = {
  'POST /identities': CreateIdentityResponses;
  'GET /identities/{id}': GetIdentityResponses;
  'DELETE /identities/{id}': DeleteIdentityResponses;
  'GET /api-credentials': GetApiCredentialsResponses;
  'POST /api-credentials': CreateApiCredentialsResponses;
  'DELETE /api-credentials/{api_client_id}': DeleteApiCredentialsResponses;
  'GET /identity-providers': GetIdentityProvidersResponses;
  'POST /identity-providers': CreateIdentityProviderResponses;
  'GET /identity-providers/{identity_provider_id}': GetIdentityProviderResponses;
  'PUT /identity-providers/{identity_provider_id}': UpdateIdentityProviderResponses;
  'DELETE /identity-providers/{identity_provider_id}': DeleteIdentityProviderResponses;
};
export class IdentityServiceV1 {
  constructor(private readonly options: ClientOptions) {}
  post<Url extends PostUrl>(
    url: Url,
    input: RequestsByMethodAndPath[`POST ${Url}`],
  ): FluentExecution<ResponsesByMethodAndPath[`POST ${Url}`]>;
  post(url: string, input: OperationInput = {}): FluentExecution<Record<number, any>> {
    return new Execution(this.options, 'POST', url, input, 1);
  }
  get<Url extends GetUrl>(
    url: Url,
    input: RequestsByMethodAndPath[`GET ${Url}`],
  ): FluentExecution<ResponsesByMethodAndPath[`GET ${Url}`]>;
  get(url: string, input: OperationInput = {}): FluentExecution<Record<number, any>> {
    return new Execution(this.options, 'GET', url, input, 1);
  }
  delete<Url extends DeleteUrl>(
    url: Url,
    input: RequestsByMethodAndPath[`DELETE ${Url}`],
  ): FluentExecution<ResponsesByMethodAndPath[`DELETE ${Url}`]>;
  delete(url: string, input: OperationInput = {}): FluentExecution<Record<number, any>> {
    return new Execution(this.options, 'DELETE', url, input, 1);
  }
  put<Url extends PutUrl>(
    url: Url,
    input: RequestsByMethodAndPath[`PUT ${Url}`],
  ): FluentExecution<ResponsesByMethodAndPath[`PUT ${Url}`]>;
  put(url: string, input: OperationInput = {}): FluentExecution<Record<number, any>> {
    return new Execution(this.options, 'PUT', url, input, 1);
  }
}
