export const suggestAnEdit = 'Suggest an edit';
export const disclaimer = 'Our team will review your edits and update the information';
export const incorrectAddress = 'Incorrect address';
export const incorrectPhoneNumber = 'Incorrect phone number';
export const incorrectFaxNumber = 'Incorrect fax number';
export const suggestFaxNumber = 'Suggest a fax number';
export const newPatients = 'Accepting new patients at this location?';
export const optionalAddress = 'Corrected address (Optional)';
export const optionalPhone = 'Corrected phone number (Optional)';
export const optionalFax = 'Corrected fax number (Optional)';
export const cancelButtonText = 'Cancel';
export const submitButtonText = 'Submit';
export const submitButtonDemoText = 'Submit (Disabled for demo)';
export const yesOption = 'Yes';
export const noOption = 'No';
export const notSureOption = 'Not sure';
export const addressPlaceholder = 'Address';
export const cityPlaceholder = 'City';
export const statePlaceholder = 'State';
export const zipCodePlaceholder = 'Zip Code';
export const phoneNumberPlaceholder = 'Phone number';
export const faxNumberPlaceholder = 'Fax number';
