import React from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { AuthLogin, useAuth } from '@garner-health/components-auth';
import { FixedWidthLayout } from '@garner-health/components-common';
import { useStringResource } from '@garner-health/lib-ui-content-management';
import { analytics, Event } from '~/analytics';
import { routes } from '~/router/routes';

// In its own component to appease hooks ordering rules
const LoginPageContents = () => {
  const navigate = useNavigate();

  return (
    <FixedWidthLayout maxWidth="sm">
      <AuthLogin
        forgotPasswordPageLink={routes.forgotPassword()}
        headingText={useStringResource('auth', 'headingText')}
        loginText={useStringResource('auth', 'loginText')}
        continueText={useStringResource('auth', 'continueText')}
        forgotPasswordText={useStringResource('auth', 'forgotPasswordText')}
        emailLabel={useStringResource('auth', 'emailLabel')}
        passwordLabel={useStringResource('auth', 'passwordLabel')}
        errorText={{
          UNKNOWN: useStringResource('auth', 'unknownErrorText'),
          NOT_AUTHORIZED: useStringResource('auth', 'notAuthorizedErrorText'),
        }}
        signInCallback={({ email, redirect }) => {
          analytics.track(Event.SIGN_IN, { email, authType: 'PASSWORD' });
          redirect ? navigate(redirect) : navigate(routes.home());
        }}
        changePasswordCallback={() => navigate(routes.resetPassword())}
      />
    </FixedWidthLayout>
  );
};

export const LoginPage = () => {
  const auth = useAuth();

  if (auth.state === 'LOGGED_IN') return <Navigate to={routes.home()} />;

  return <LoginPageContents />;
};
