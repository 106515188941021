import { useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ProviderResultItem, ProvidersResponseSetType } from '~/clients';
import { fromSearchParams, toSearchParams } from '~/util';
import { DEFAULT_SORT_METHOD, isSortMethod, SortMethod, sortMethods } from '~/components/provider-search/sort-method';

const validSortMethods: Record<ProvidersResponseSetType, SortMethod[]> = {
  facility: [sortMethods.recommended, sortMethods.distance],
  physician: [sortMethods.recommended, sortMethods.distance, sortMethods.overallScore],
  name: [sortMethods.recommended, sortMethods.distance, sortMethods.overallScore],
};
const sortFuncs: Record<SortMethod, (providers: ProviderResultItem[]) => ProviderResultItem[]> = {
  recommended: providers => providers,
  distance: providers => [...providers].sort((a, b) => a.distanceMi! - b.distanceMi!),
  overallScore: providers => [...providers].sort((a, b) => (b.overallScore ?? 0) - (a.overallScore ?? 0)),
};

function parseSortMethod(params: URLSearchParams): SortMethod {
  const sortMethod = params.get('sortMethod');
  if (!sortMethod || !isSortMethod(sortMethod)) {
    return DEFAULT_SORT_METHOD;
  }

  return sortMethod;
}

function getValidatedSortMethod(params: URLSearchParams, searchType: ProvidersResponseSetType) {
  const sortMethod = parseSortMethod(params);
  if (validSortMethods[searchType].includes(sortMethod)) {
    return sortMethod;
  }

  return DEFAULT_SORT_METHOD;
}

export function useSortedResults(
  searchResults: ProviderResultItem[],
  ProvidersResponseSetType: ProvidersResponseSetType,
) {
  const [searchParams, setSearchParams] = useSearchParams();

  const sortMethod = getValidatedSortMethod(searchParams, ProvidersResponseSetType);

  function setSortMethod(value: SortMethod) {
    if (searchParams.get('sortMethod') === value) return;

    setSearchParams(prev =>
      toSearchParams({
        ...fromSearchParams(prev),
        sortMethod: value,
      }),
    );
  }

  const sortedProviders = useMemo(() => {
    return sortFuncs[sortMethod](searchResults);
  }, [searchResults, sortMethod]);

  return {
    sortMethod,
    setSortMethod,
    sortedProviders,
  };
}
