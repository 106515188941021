export const submitSearchButtonText = 'Search';
export const providerDistanceMilesText = '{distance:distance} miles';
export const careGoalPlaceholderText = 'Specialty or condition';
export const careGoalExampleText = 'e.g. Cardiologist';
export const providerNamePlaceholderText = 'Provider Name';
export const locationPlaceholderText = 'Location';
export const locationExampleText = 'Zip code or address';
export const networkPlaceholderText = 'Network';
export const networkExampleText = 'Network name';
export const startNewSearchHeaderText = 'Start your search above to see results';
export const startNewSearchSubHeaderText = 'You can search by specialty or condition';
export const specialtyOrNameValidationText = 'At least specialty or provider name is required';
export const noResultsHeaderText = 'No results';
export const noResultsSubHeaderText = 'Please try another search';
export const tieredNetworkSearchHeaderText = 'You are searching a Tiered Network';
export const tieredNetworkSearchInstructions =
  'Please make every effort to find a provider in Tier 1. If results are not available, expand your search to the next tier.';
export const providerNameAndSpecialtyColumnLabel = 'Provider';
export const providerDistanceColumnLabel = 'Distance';
export const providerOverallScoreLabel = 'Overall score';
export const notPreferredText = 'Not preferred';
export const providerSortRecommendedLabel = 'Recommended';
export const languagePlaceholderText = 'Language';
export const genderPlaceholderText = 'Gender';
export const male = 'Male';
export const female = 'Female';
export const dataSecurityReminderTitle = 'Terms of service reminder';
export const dataSecurityReminderBodyText = `Your use of this tool is subject to Garner's {Terms of Service}`;
export const dataSecurityReminderBodySubtext =
  'Please be advised that it is a violation of those terms to save or copy any data from the tool, including to spreadsheets or other databases.';
export const dataSecurityReminderCheckboxText = 'By checking this box, you agree to the above linked Terms of Service';
export const agreeToDataSecurityModalContinueButtonText = 'Continue';
export const notSupportedHeaderText = 'Email support';
export const notSupportedHeaderSubtext =
  "If you'd like help finding a provider that offers this type of care, please email support at {recommendation-support@getgarner.com}";
export const sortByLabel = 'Sort by:';
export const termsOfService = 'Terms of Service';
