import { cloneElement, isValidElement } from 'react';
import { keyForInterpolation } from './keygen';
const interpolationRegex = /\{(?:([^:}]+):)?([^}]*)\}/g;
export function interpolate(content, replacer) {
    let result;
    const parts = [];
    let lastIndex = 0;
    for (let i = 0; (result = interpolationRegex.exec(content)); i++) {
        if (lastIndex !== result.index)
            parts.push(content.slice(lastIndex, result.index));
        let replacement = replacer(result[2], result[1] || i);
        if (isValidElement(replacement) && !replacement.key) {
            replacement = cloneElement(replacement, { key: keyForInterpolation(i) });
        }
        parts.push(replacement);
        lastIndex = interpolationRegex.lastIndex;
    }
    if (lastIndex === 0)
        return content;
    if (lastIndex !== content.length)
        parts.push(content.slice(lastIndex, content.length));
    return parts;
}
export function interpolateString(content, stringReplacer) {
    let result;
    let parts = '';
    let lastIndex = 0;
    for (let i = 0; (result = interpolationRegex.exec(content)); i++) {
        if (lastIndex !== result.index)
            parts += content.slice(lastIndex, result.index);
        parts += stringReplacer(result[2], result[1] || i);
        lastIndex = interpolationRegex.lastIndex;
    }
    if (lastIndex === 0)
        return content;
    if (lastIndex !== content.length)
        parts += content.slice(lastIndex, content.length);
    return parts;
}
