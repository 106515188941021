/**
 * Determines whether two arrays are equal.
 *
 * The order of the items in the arrays do not matter
 * - e.g. `arraysAreEqual([1, 2, 3], [1, 3, 2])` returns `true`
 */
export function arraysAreEqual(arr1, arr2, keyResolver) {
    // If we're comparing an array against itself, return true
    if (arr1 === arr2) {
        return true;
    }
    // If the lengths are different, return false
    if (arr1.length !== arr2.length) {
        return false;
    }
    return getDifferences(arr1, arr2, keyResolver).equal;
}
/**
 * Determines the differences between two arrays.
 *
 * The order of the items in the arrays do not matter
 * - e.g. `diffArrays([1, 2, 3], [1, 3, 2])` returns `{ unchanged: [1, 2, 3]}`
 */
export function diffArrays(prev, next, keyResolver) {
    const changeResult = getDifferences(prev, next, keyResolver);
    const result = {
        added: [],
        removed: [],
        unchanged: [],
    };
    Object.values(changeResult.changes).forEach(change => {
        switch (change.changeCode) {
            case -1: {
                const prev = change.prev;
                if (!prev) {
                    throw Error(`Item has been removed but 'prev' has not been set`);
                }
                result.removed.push(prev);
                break;
            }
            case 0: {
                const prev = change.prev;
                if (!prev) {
                    throw Error(`Item is unchanged but 'prev' has not been set`);
                }
                result.unchanged.push(prev);
                break;
            }
            case 1: {
                const next = change.next;
                if (!next) {
                    throw Error(`Item has been added but 'next' has not been set`);
                }
                result.added.push(next);
                break;
            }
        }
    });
    return result;
}
function getDifferences(prev, next, keyResolver) {
    const changes = {};
    // Set all keys from prev to -1
    for (const item of prev) {
        changes[keyResolver(item)] = {
            changeCode: -1,
            prev: item,
        };
    }
    // Iterate over next and add 1 to the value of each key. Keys that exist in prev will become 0; keys that don't exist in prev will become 1
    for (const item of next) {
        const key = keyResolver(item);
        if (!changes[key]) {
            changes[key] = {
                changeCode: 1,
                next: item,
            };
        }
        else {
            changes[key].changeCode = 0;
        }
    }
    // Iterate over all properties in the keys record and immediately return false if a non-zero value is found
    for (const value of Object.values(changes)) {
        if (value.changeCode !== 0) {
            return {
                equal: false,
                changes: changes,
            };
        }
    }
    // There were only zeros in the keys record
    return {
        equal: true,
        changes: changes,
    };
}
